import { baseDataFields, baseRatesFields } from './calcfields';

export const styles = {
        divTable: { display: 'table', width: 'auto' },
        divRow: { display: 'table-row', width: 'auto' },
        divCell: { float: 'left', display: 'table-column', width: 200 },
        divTableDb: { display: 'table', width: 'auto', marginTop: 20 },
        divRowDb: { display: 'table-row', width: 'auto' },
        divCellDb: { float: 'left', display: 'table-column', width: '47%', marginLeft: 5, marginRight: 5 },
        hrSpacing: { marginTop: 25, marginBottom: 25, width: '75%' },
    };

export const SiteIDData = {
    1: {
        name: "expresszkolcson.hu",
        url: "https://expresszkolcson.hu",
    },
    2: {
        name: "akolcson.hu",
        url: "https://akolcson.hu",
    },
    3: {
        name: "hitelstart.hu",
        url: "https://hitelstart.hu",
    },

    4: {
        name: "banknavigator.hu",
        url: "https://banknavigator.hu",
    }
};

export const Sites = [
    {id: 1, name: "expresszkolcson.hu"},
    {id: 2, name: "akolcson.hu"},
    {id: 4, name: "banknavigator.hu"},
];

export const hitelnetStatuses = [
    {status: 0,   name: "Sikeresen beküldve" },
    {status: 1,   name: "Lead már létezik a Hitelnet adatbázisában" },
    {status: 23,  name: "Kapcsolatfelvétel folyamatban" },
    {status: 2,   name: "Megkeresve - nem vette fel a telefont 1. alkalommal" },
    {status: 3,   name: "Nem értem el, nem jó a telefonszám vagy e-mail cím" },
    {status: 4,   name: "Sikeres kapcsolat felvétel, később visszahívást kért" },
    {status: 5,   name: "Sikeres kapcsolatfelvétel, később lesz aktuális" },
    {status: 6,   name: "Sikeres kapcsolatfelvétel, 3 hónapon belül aktuális" },
    {status: 7,   name: "Sikeres kapcsolatfelvétel, időpont egyeztetve" },
    {status: 8,   name: "Adatbekérő emailben elküldve" },
    {status: 25,  name: "Sikeres kapcsolatfelvétel, MEGHIÚSULT" },
    {status: 242, name: "Sikeres kapcsolatfelvétel, ügyfél dokumentumokat gyűjt" },
    {status: 20,  name: "Előkészítés alatt" },
    {status: 16,  name: "Feldolgozás alatt" },
    {status: 24,  name: "Igény elhalasztva" },
    {status: 17,  name: "Sikeres közvetítés" },
    {status: 18,  name: "Nem hitelképes" },
    {status: 19,  name: "Visszalépett" },
    {status: 21,  name: "Bank által elutasítva" },
    {status: 244, name: "Megkeresve - nem vette fel a telefont 2. alkalommal" },
    {status: 245, name: "Megkeresve - nem vette fel a telefont 3. alkalommal" },
    {status: 246, name: "Megkeresve - nem vette fel a telefont 4. alkalommal" },
    {status: 247, name: "Megkeresve - nem vette fel a telefont 5. alkalommal" },
    {status: 248, name: "Tartósan nem elérhető - zárható lead" },
    {status: 249, name: "Megkeresve - nem jó a telefonszám (nem kapcsolható v. hibás)" },
    {status: 250, name: "Sikeres kapcsolatfelvétel, 3-12 hónapon belül aktuális" },
    {status: 251, name: "Sikeres kapcsolatfelvétel, 12 hónapon túl aktuális" },
    {status: 252, name: "Sikeres időpont egyezetetés ellenére az ügyfél nem elérhető, nem jelent meg" },
];

export const CRMModuleData = [
    { id: '36', name: 'ERSTE' },
    { id: '35', name: 'K&H' },
    { id: '31', name: 'Minikölcsön' },
    { id: '34', name: 'MKB' },
    { id: '32', name: 'Sberbank' },
    { id: '37', name: 'Takarékbank' },
    { id: '38', name: 'Unicredit' },
];

export const SitesData = {
    1: {
        name: "E",
        bgcolor: "expressz",
    },
    2: {
        name: "A",
        bgcolor: "akolcson",
    },
    3: {
        name: "H",
        bgcolor: "hitelstart",
    },

    4: {
        name: "B",
        bgcolor: "banknavigator",
    }
};

export const DataBlocksLabelData = {
    0: {
        label1: "THM",
        label2: "Futamidő",
        label3: "Hitelösszeg",
    },
    11: {
        label1: "THM",
        label2: "Futamidő",
        label3: "Hitelösszeg",
    },
    26: {
        label1: "EBKM",
        label2: "Futamidő",
    },
    37: {
        label1: "Minimum összeg",
        label2: "Díj",
        label3: "Idő",
    },
    38: {
        label1: "THM",
        label2: "EBKM",
    },
    47: {
        label1: "Kamat",
        label2: "Futamidő",
        label3: "Hitelösszeg",
    },
    53: {
    	label1: "THM",
	    label2: "Futamidő",
	    label3: "Hitelösszeg",
    },
    65: {
        label1: "THM",
        label2: "Futamidő",
        label3: "Hitelösszeg",
    },
    66: {
        label1: "THM",
        label2: "Futamidő",
        label3: "Hitelösszeg",
    },
    67: {
        label1: "Számlavezetési díj",
        label2: "Választható kártya",
    },
    68: {
        label1: "Számlavezetési díj",
        label2: "Választható kártya",
    },
    69: {
        label1: "Kártya típusa",
        label2: "Kamatmentesség",
        label3: "THM",
    },
    70: {
        label1: "Kártya típusa",
        label2: "Kamatmentesség",
        label3: "THM",
    },
    90: {
        label1: "THM",
        label2: "Futamidő",
        label3: "Hitelösszeg",
    },
    1026: {
        label1: "Leköthető összeg",
        label2: "EBKM",
        label3: "Futamidő",
    },
};

//DataBlocksLabelData[72] = DataBlocksLabelData[47];

const BlocksMapping = {
    0:  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 71],
    11: [12, 13, 14, 15, 16, 17, 18, 19, 20, 54, 55, 72, 90, 91, 1027],
    26: [27, 28, 29, 30, 31],
    37: [],
    38: [39, 40, 41, 42, 43, 44, 45, 46],
    47: [48, 49, 50, 51, 52, 57, 58, 59, 60],
    53: [],
    65: [],
    66: [],
    67: [1024],
    68: [],
    69: [],
    70: [],
    72: [],
    1026: [],
    // Direkt kikommentelve kell hagyni, mert ez az a kategoria, aminek nem kell lennie labeljeinek (Banki aloldalak)
    // 73: [74, 75, 76],
};
for (const mainCat in BlocksMapping) {
    BlocksMapping[mainCat].forEach(function(subCat) {
        DataBlocksLabelData[subCat] = DataBlocksLabelData[mainCat];
        baseDataFields[subCat] = baseDataFields[mainCat];
        baseRatesFields[subCat] = baseRatesFields[mainCat];
    });
};
export const findLabels = function(categories) { return findByCategories(categories, DataBlocksLabelData) || {}; };
export const findDataFields = function(categories) { return findByCategories(categories, baseDataFields) || baseDataFields[0]; };
export const findRatesFields = function(categories) { return findByCategories(categories, baseRatesFields) || baseRatesFields[0]; };

function findByCategories(categories, data) {
    var found;
    if (categories && typeof categories == "object")
        categories.forEach(function(category) {
            if (data.hasOwnProperty(category)) {
                found = data[category];
                return;
            }
        });
    return found;
};

/*
    { id: 1, name: "1 - Sberbank" },
    { id: 4, name: "4 - ERSTE MiniCRM" },
    { id: 5, name: "5 - Jelzálog CRM" },
    { id: 7, name: "7 - ERSTE hitelkártya" },

*/

export const LeadFormData = [
    /* { id:  1,  name: "MiniCRM - expresszkolcson.hu - 1" },
    { id:  2,  name: "Belso CRM (regi Finex) - 2" },
    { id: 28,  name: "City Leasing - 28"}, */
    { id: 20,  name: "ERSTE Babavaro - 20" },
    { id: 21,  name: "ERSTE Lakashitel - 21" },
    { id: 34,  name: "ERSTE Bankszámla - 34" },
    { id: 34,  name: "ERSTE Hitelkártya - 34" },
    { id: 6,   name: "Hitelnet default form - 6"},
    { id: 600, name: "Hitelnet Cetelem Személyi Kölcsön - 600"},
    { id: 601, name: "Hitelnet OTP Személyi Kölcsön - 601"},
    { id: 602, name: "Hitelnet ERSTE Személyi Kölcsön - 602"},
    { id: 603, name: "Hitelnet Unicredit Személyi Kölcsön - 603"},
    { id: 604, name: "Hitelnet Magnet Személyi Kölcsön - 604"},
    { id: 610, name: "Hitelnet OTP Végig Fix Lakáshitel Egyszeri kamatcsökkentéssel - 610"},
    { id: 611, name: "Hitelnet OTP Végig Fix Forint Jelzáloghitel Egyszeri kamatcsökkentéssel - 611"},
    { id: 612, name: "Hitelnet Erste Minősített Fogyasztóbarát Lakáshitelek - 612"},
    { id: 613, name: "Hitelnet Erste Piaci Kamatozású Lakáshitelek - 613"},
    { id: 614, name: "Hitelnet Erste Szabad felhasználású jelzáloghitelek - 614"},
    { id: 615, name: "Hitelnet UniCredit Stabil Kamat hitel lakáscélra - 615"},
    { id: 616, name: "Hitelnet UniCredit Minősített Fogyasztóbarát Lakáshitelek - 616"},
    { id: 617, name: "Hitelnet MagNet Stabil Ötös/Tízes Jelzáloghitel - 617"},
    { id: 618, name: "Hitelnet MagNet Stabil Ötös/Tízes Lakáshitel - 618"},
    { id: 620, name: "Hitelnet OTP Otthonteremtési Minősített Fogyasztóbarát Lakáshitel (CSOK+Hitel, Falusi CSOK+Hitel) - 620"},
    { id: 621, name: "Hitelnet Erste Többgyermekes családok otthonteremtési kamattámogatott lakáshitele (CSOK+Hitel, Falusi CSOK+Hitel) - 621"},
    { id: 622, name: "Hitelnet UniCredit Többgyermekes családok otthonteremtési kamattámogatott hitele (CSOK+Hitel) - 622"},
    { id: 623, name: "Hitelnet MagNet CSOK + Lakáshitel - 623"},
    { id: 13,  name: "KH Babaváró - 13" },
    { id: 27,  name: "KH Lakáshitel - 27" },
    { id: 33,  name: "KH Bankszámla - 33" },
    { id: 33,  name: "KH Hitelkártya - 33" },
    /* { id:  8,  name: "Minikolcson - 8" },
    { id: 11,  name: "MKB Szemelyi Kolcson - 11" },
    { id: 12,  name: "MKB Babavaro - 12" },
    { id: 23,  name: "MKB Lakashitel - 23" },
    { id: 26,  name: "MKB Vállalkozói - 26" },
    { id: 30,  name: "MKB Bankszámla - 30" },
    { id: 30,  name: "MKB Hitelkártya - 30" },
    { id: 36,  name: "OTP leadek - 36" },
    { id:  9,  name: "Sberbank Szemelyi Kolcson - 9" },
    { id: 10,  name: "Sberbank Babavaro - 10" },
    { id: 22,  name: "Sberbank Lakashitel - 22" },
    { id: 25,  name: "Sberbank Vállalkozói - 25" },
    { id: 31,  name: "Sberbank Bankszámla - 31" },
    { id: 31,  name: "Sberbank Hitelkártya - 31" },
    { id: 14,  name: "Takarekbank Szemelyi Kolcson - 14" },
    { id: 15,  name: "Takarekbank Babavaro - 15" },
    { id: 16,  name: "Takarekbank Lakashitel - 16" },
    { id: 29,  name: "Takarekbank Bankszámla - 29" }, */
    { id: 17,  name: "Unicredit Szemelyi Kolcson - 17" },
    { id: 18,  name: "Unicredit Babavaro - 18" },
    { id: 19,  name: "Unicredit Lakashitel - 19" },
    { id: 32,  name: "Unicredit Bankszámla - 32" },
    { id: 32,  name: "Unicredit Hitelkártya - 32" },
];
