import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    hitelstart: {
        margin: 4,
        color: '#fff',
        backgroundColor: '#c7b18f',
    },
    akolcson: {
        margin: 4,
        color: '#fff',
        backgroundColor: '#fc763e',
    },
    banknavigator: {
        margin: 4,
        color: '#fff',
        backgroundColor: '#49bbe4',
    },
    expressz: {
        margin: 4,
        color: '#fff',
        backgroundColor: '#98cb01',
    },
};

export const MyAvatar = ({
    className,
    classes = {},
    source,
    sitename,
    bgcolor,
    record = {},
}) => {
    var myClass = classes[bgcolor];
    return (
                <Avatar className={myClass}>{sitename}</Avatar>
    );
};

MyAvatar.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    elStyle: PropTypes.object,
    sortBy: PropTypes.string,
    source: PropTypes.string.isRequired,
    record: PropTypes.object,
    sitename: PropTypes.string,
    bgcolor: PropTypes.string,
};

// wat? TypeScript looses the displayName if we don't set it explicitly
MyAvatar.displayName = 'MyAvatar';

const MyPureAvatar = withStyles(styles)(pure(MyAvatar));

export default MyPureAvatar;
