import React from 'react';
import { Mutation } from 'react-admin';
import Button from '@material-ui/core/Button';
import Autorenew from '@material-ui/icons/Autorenew';

const CommitButton = ({ record, resource, redirect }) => {
    const options = {
        undoable: false,
        onSuccess: {
            notification: { body: 'Sikeresen újraküldve!', level: 'info' },
            redirectTo: `/ads/${record.id}/show`,
        },
        onError: {
            notification: { body: 'HIBA: Újraküldés nem sikerult!', level: 'warning' }
        }
    };

    const payload = { id: record.id };

    return (
        <Mutation
        type="UPDATE"
        resource="ads-commit"
        payload={payload}
        options={options}
        >
        {(commit) => (
            <Button size="small" color="primary" onClick={commit}>
                <Autorenew />Commit Rev
            </Button>
        )}
        </Mutation>
    );
}

export default CommitButton;