import React from 'react';
import {
    List,
    Edit,
    Show,
    Create,
    Filter,
    Datagrid,
    TextField,
    TextInput,
    LongTextInput,
    TabbedForm,
    FormTab,
    Pagination,
    ReferenceInput,
    SelectInput,
    SelectField,
    TabbedShowLayout,
    Tab,
    NumberField,
    NumberInput,
    regex,
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { ZeroOneBooleanInput, ZeroOneBooleanField } from '../field/bool';

/*
JSON Fieldek:
    {
        "category": null,
        "category_id": null,
        "credit_type": null,
        "def_deposit": null,
        "def_income": null,
        "def_months": null,
        "def_savings": null,
        "def_sum": null,
        "deposit_select_base": null,
        "deposit_select_step": null,
        "deposit_step": null,
        "favor": null,
        "form_id": null,
        "header": null,
        "id": 1,
        "income_select_base": null,
        "income_select_step": null,
        "income_step": null,
        "is_year": 0,
        "listing_only": 1,
        "location": null,
        "max_deposit": null,
        "max_income": null,
        "max_months": null,
        "max_savings": null,
        "max_sum": null,
        "min_deposit": null,
        "min_income": null,
        "min_months": null,
        "min_savings": null,
        "min_sum": null,
        "months_select_base": null,
        "months_select_step": null,
        "months_step": null,
        "no_featured": null,
        "ordering": 1,
        "savings_select_base": null,
        "savings_select_step": null,
        "savings_step": null,
        "sum_select_base": null,
        "sum_select_step": null,
        "sum_step": null,
        "url": "banklista",
        "user": null
    },
*/


const validateEntry = regex(/^[^"]*$/, 'Hibás adat!');

const CatMapFilter = ( { permissions, ...props }) => (
    <Filter {...props}>
        <TextInput label="URL keresése" source="url" alwaysOn />
    </Filter>
);

const CategoryPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />

/*
szerintem ami default szerkeszthetőnek érdemes:

category.name - Kategória
def_sum - hitelösszeg
def_months - futamidő (hó)
def_income - jövedelem
def_deposit - számlára érkező
def_savings - megtakarítás
listing_only - csak lista (nincs kalkulátor)
user - csak ez a hirdető
no_featured - nincs kiemelés
*/

const BasicSettings = ({ record, basePath }) => {
    return (
        <>
            <div class="table">
                <div class="row">
                    <div class="cellkey">
                        <Typography color="inherit">ID:</Typography>
                    </div>
                    <div class="cellvalue">
                        <TextField record={record} basePath={basePath} source="id" label="" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">URL:</Typography>
                    </div>
                    <div class="cellvalue">
                        <LongTextInput record={record} basePath={basePath} source="url" label="" validate={validateEntry} />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">Hirdető:</Typography>
                    </div>
                    <div class="cellvalue">
                        <ReferenceInput record={record} basePath={basePath} label="" source="user" reference="users">
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">Kategória:</Typography>
                    </div>
                    <div class="cellvalue">
                        <ReferenceInput record={record} basePath={basePath} label="" source="category_id" reference="categories_list" allowEmpty>
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                    </div>
                </div>

                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">Számlára érkező jövedelem:</Typography>
                    </div>
                    <div class="cellvalue">
                        <NumberInput record={record} basePath={basePath} source="def_deposit" label="" />
                    </div>
                </div>

                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">Jövedelem:</Typography>
                    </div>
                    <div class="cellvalue">
                        <NumberInput record={record} basePath={basePath} source="def_income" label="" />
                    </div>
                </div>

                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">Futamidő (hó):</Typography>
                    </div>
                    <div class="cellvalue">
                        <NumberInput record={record} basePath={basePath} className="spacer" source="def_months" label="" />
                        <ZeroOneBooleanInput record={record} basePath={basePath} className="spacer checkbox-inline" label="Év alapú?" source="is_year" />

                    </div>
                </div>

                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">Életkor:</Typography>
                    </div>
                    <div class="cellvalue">
                        <NumberInput record={record} basePath={basePath} source="def_age" label="" />
                    </div>
                </div>

                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">Megtakarítás:</Typography>
                    </div>
                    <div class="cellvalue">
                        <NumberInput record={record} basePath={basePath} source="def_savings" label="" />
                    </div>
                </div>

                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">Hitelösszeg:</Typography>
                    </div>
                    <div class="cellvalue">
                        <NumberInput record={record} basePath={basePath} source="def_sum" label="" />
                    </div>
                </div>

                <div class="row">
                    <div class="cellkey add-padding-1">
                        <Typography color="inherit">Csak lista:</Typography>
                    </div>
                    <div class="cellvalue">
                        <ZeroOneBooleanInput record={record} basePath={basePath} source="listing_only" label="" />
                    </div>
                </div>

                <div class="row">
                    <div class="cellkey add-padding-1">
                        <Typography color="inherit">Nincs kiemelés:</Typography>
                    </div>
                    <div class="cellvalue">
                        <ZeroOneBooleanInput record={record} basePath={basePath} source="no_featured" label="" />
                    </div>
                </div>
            </div>
        </>
    );
}

const AdvancedSettings = ({ record, basePath }) => {
    return (
        <>
            <div class="table">
                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">Display Sorting:</Typography>
                    </div>
                    <div class="cellvalue">
                        <SelectInput record={record} basePath={basePath} label="" source="display_sorting" choices={[
                            { id: 0, name: " " },
                            { id: 1, name: "THM" },
                        ]} optionText="name" optionValue="id" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">Credit Type:</Typography>
                    </div>
                    <div class="cellvalue">
                        <NumberInput record={record} basePath={basePath} label="" source="credit_type" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">Favor:</Typography>
                    </div>
                    <div class="cellvalue">
                        <NumberInput record={record} basePath={basePath} label="" source="favor" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey add-padding-1">
                        <Typography color="inherit">Header:</Typography>
                    </div>
                    <div class="cellvalue">
                        <ZeroOneBooleanInput record={record} basePath={basePath} label="" source="header" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">Location:</Typography>
                    </div>
                    <div class="cellvalue">
                        <TextInput record={record} basePath={basePath} label="" source="location" validate={validateEntry} />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">Form ID:</Typography>
                    </div>
                    <div class="cellvalue">
                        <TextInput record={record} basePath={basePath} label="" source="form_id" validate={validateEntry} />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">Ordering:</Typography>
                    </div>
                    <div class="cellvalue">
                        <NumberInput record={record} basePath={basePath} label="" source="ordering" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">Deposit:</Typography>
                    </div>
                    <div class="cellvalue">
                        <NumberInput record={record} basePath={basePath} className="spacer" label="Minimum" source="min_deposit" />
                        <NumberInput record={record} basePath={basePath} className="spacer" label="Maximum" source="max_deposit" />
                        <NumberInput record={record} basePath={basePath} className="spacer" label="Step" source="deposit_step" />
                        <NumberInput record={record} basePath={basePath} className="spacer" label="Select Base" source="deposit_select_base" />
                        <NumberInput record={record} basePath={basePath} className="spacer" label="Select Step" source="deposit_select_step" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">Income:</Typography>
                    </div>
                    <div class="cellvalue">
                        <NumberInput record={record} basePath={basePath} className="spacer" label="Minimum" source="min_income" />
                        <NumberInput record={record} basePath={basePath} className="spacer" label="Maximum" source="max_income" />
                        <NumberInput record={record} basePath={basePath} className="spacer" label="Step" source="income_step" />
                        <NumberInput record={record} basePath={basePath} className="spacer" label="Select Base" source="income_select_base" />
                        <NumberInput record={record} basePath={basePath} className="spacer" label="Select Step" source="income_select_step" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">Months:</Typography>
                    </div>
                    <div class="cellvalue">
                        <NumberInput record={record} basePath={basePath} className="spacer" label="Minimum (hónap)" source="min_months" />
                        <NumberInput record={record} basePath={basePath} className="spacer" label="Maximum (hónap)" source="max_months" />
                        <NumberInput record={record} basePath={basePath} className="spacer" label="Step (év, ha év alapú)" source="months_step" />
                        <NumberInput record={record} basePath={basePath} className="spacer label-top" label="Select Base (év, ha év alapú)" source="months_select_base" />
                        <NumberInput record={record} basePath={basePath} className="spacer label-top" label="Select Step (év, ha év alapú)" source="months_select_step" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">Age:</Typography>
                    </div>
                    <div class="cellvalue">
                        <NumberInput record={record} basePath={basePath} className="spacer" label="Minimum (év)" source="min_age" />
                        <NumberInput record={record} basePath={basePath} className="spacer" label="Maximum (év)" source="max_age" />
                        <NumberInput record={record} basePath={basePath} className="spacer" label="Step (év)" source="age_step" />
                        <NumberInput record={record} basePath={basePath} className="spacer label-top" label="Select Base (év)" source="age_select_base" />
                        <NumberInput record={record} basePath={basePath} className="spacer label-top" label="Select Step (év)" source="age_select_step" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">Savings:</Typography>
                    </div>
                    <div class="cellvalue">
                        <NumberInput record={record} basePath={basePath} className="spacer" label="Minimum" source="min_savings" />
                        <NumberInput record={record} basePath={basePath} className="spacer" label="Maximum" source="max_savings" />
                        <NumberInput record={record} basePath={basePath} className="spacer" label="Step" source="savings_step" />
                        <NumberInput record={record} basePath={basePath} className="spacer" label="Select Base" source="savings_select_base" />
                        <NumberInput record={record} basePath={basePath} className="spacer" label="Select Step" source="savings_select_step" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">Sum:</Typography>
                    </div>
                    <div class="cellvalue">
                        <NumberInput record={record} basePath={basePath} className="spacer" label="Minimum" source="min_sum" />
                        <NumberInput record={record} basePath={basePath} className="spacer" label="Maximum" source="max_sum" />
                        <NumberInput record={record} basePath={basePath} className="spacer" label="Step" source="sum_step" />
                        <NumberInput record={record} basePath={basePath} className="spacer" label="Select Base" source="sum_select_base" />
                        <NumberInput record={record} basePath={basePath} className="spacer" label="Select Step" source="sum_select_step" />
                    </div>
                </div>
            </div>
        </>
    );
}

const AdvancedShowSettings = ({ record, basePath }) => {
    return (
        <>
            <div class="table">
                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">Display Sorting:</Typography>
                    </div>
                    <div class="cellvalue">
                        <SelectField record={record} basePath={basePath} label="" source="display_sorting" choices={[
                            { id: 0, name: " " },
                            { id: 1, name: "THM" },
                        ]} optionText="name" optionValue="id" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey">
                        <Typography color="inherit">Credit Type:</Typography>
                    </div>
                    <div class="cellvalue">
                        <NumberField record={record} basePath={basePath} label="" source="credit_type" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey">
                        <Typography color="inherit">Favor:</Typography>
                    </div>
                    <div class="cellvalue">
                        <NumberField record={record} basePath={basePath} label="" source="favor" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey">
                        <Typography color="inherit">Header:</Typography>
                    </div>
                    <div class="cellvalue">
                        <ZeroOneBooleanField record={record} basePath={basePath} label="" source="header" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey">
                        <Typography color="inherit">Location:</Typography>
                    </div>
                    <div class="cellvalue">
                        <TextField record={record} basePath={basePath} label="" source="location" validate={validateEntry} />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey">
                        <Typography color="inherit">Form ID:</Typography>
                    </div>
                    <div class="cellvalue">
                        <TextField record={record} basePath={basePath} label="" source="form_id" validate={validateEntry} />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey">
                        <Typography color="inherit">Ordering:</Typography>
                    </div>
                    <div class="cellvalue">
                        <NumberField record={record} basePath={basePath} label="" source="ordering" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey">
                        <Typography color="inherit">Deposit:</Typography>
                    </div>
                    <div class="cellvalue">
                        <NumberField record={record} basePath={basePath} className="spacer" label="Minimum" source="min_deposit" />
                        <NumberField record={record} basePath={basePath} className="spacer" label="Maximum" source="max_deposit" />
                        <NumberField record={record} basePath={basePath} className="spacer" label="Step" source="deposit_step" />
                        <NumberField record={record} basePath={basePath} className="spacer" label="Select Base" source="deposit_select_base" />
                        <NumberField record={record} basePath={basePath} className="spacer" label="Select Step" source="deposit_select_step" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey">
                        <Typography color="inherit">Income:</Typography>
                    </div>
                    <div class="cellvalue">
                        <NumberField record={record} basePath={basePath} className="spacer" label="Minimum" source="min_income" />
                        <NumberField record={record} basePath={basePath} className="spacer" label="Maximum" source="max_income" />
                        <NumberField record={record} basePath={basePath} className="spacer" label="Step" source="income_step" />
                        <NumberField record={record} basePath={basePath} className="spacer" label="Select Base" source="income_select_base" />
                        <NumberField record={record} basePath={basePath} className="spacer" label="Select Step" source="income_select_step" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey">
                        <Typography color="inherit">Months:</Typography>
                    </div>
                    <div class="cellvalue">
                        <NumberField record={record} basePath={basePath} className="spacer" label="Minimum" source="min_months" />
                        <NumberField record={record} basePath={basePath} className="spacer" label="Maximum" source="max_months" />
                        <NumberField record={record} basePath={basePath} className="spacer" label="Step" source="months_step" />
                        <NumberField record={record} basePath={basePath} className="spacer" label="Select Base" source="months_select_base" />
                        <NumberField record={record} basePath={basePath} className="spacer" label="Select Step" source="months_select_step" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey">
                        <Typography color="inherit">Age:</Typography>
                    </div>
                    <div class="cellvalue">
                        <NumberField record={record} basePath={basePath} className="spacer" label="Minimum" source="min_age" />
                        <NumberField record={record} basePath={basePath} className="spacer" label="Maximum" source="max_age" />
                        <NumberField record={record} basePath={basePath} className="spacer" label="Step" source="age_step" />
                        <NumberField record={record} basePath={basePath} className="spacer" label="Select Base" source="age_select_base" />
                        <NumberField record={record} basePath={basePath} className="spacer" label="Select Step" source="age_select_step" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey">
                        <Typography color="inherit">Savings:</Typography>
                    </div>
                    <div class="cellvalue">
                        <NumberField record={record} basePath={basePath} className="spacer" label="Minimum" source="min_savings" />
                        <NumberField record={record} basePath={basePath} className="spacer" label="Maximum" source="max_savings" />
                        <NumberField record={record} basePath={basePath} className="spacer" label="Step" source="savings_step" />
                        <NumberField record={record} basePath={basePath} className="spacer" label="Select Base" source="savings_select_base" />
                        <NumberField record={record} basePath={basePath} className="spacer" label="Select Step" source="savings_select_step" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey">
                        <Typography color="inherit">Sum:</Typography>
                    </div>
                    <div class="cellvalue">
                        <NumberField record={record} basePath={basePath} className="spacer" label="Minimum" source="min_sum" />
                        <NumberField record={record} basePath={basePath} className="spacer" label="Maximum" source="max_sum" />
                        <NumberField record={record} basePath={basePath} className="spacer" label="Step" source="sum_step" />
                        <NumberField record={record} basePath={basePath} className="spacer" label="Select Base" source="sum_select_base" />
                        <NumberField record={record} basePath={basePath} className="spacer" label="Select Step" source="sum_select_step" />
                    </div>
                </div>
            </div>
        </>
    );
}

const BasicShowSettings = ({ record, basePath }) => {
    return (
        <>
            <div class="table">
                <div class="row">
                    <div class="cellkey">
                    <Typography color="inherit">ID:</Typography>
                    </div>
                    <div class="cellvalue">
                        <TextField record={record} basePath={basePath} source="id" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey">
                    <Typography color="inherit">URL:</Typography>
                    </div>
                    <div class="cellvalue">
                        <TextField record={record} basePath={basePath} source="url" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey">
                    <Typography color="inherit">Hirdető:</Typography>
                    </div>
                    <div class="cellvalue">
                        <NumberField record={record} basePath={basePath} source="user" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey">
                    <Typography color="inherit">Kategória:</Typography>
                    </div>
                    <div class="cellvalue">
                        <TextField record={record} basePath={basePath} source="category.name" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey">
                    <Typography color="inherit">Számlára érkező jövedelem:</Typography>
                    </div>
                    <div class="cellvalue">
                        <NumberField record={record} basePath={basePath} source="def_deposit" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey">
                    <Typography color="inherit">Jövedelem:</Typography>
                    </div>
                    <div class="cellvalue">
                        <NumberField record={record} basePath={basePath} source="def_income" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey">
                    <Typography color="inherit">Futamidő (hó):</Typography>
                    </div>
                    <div class="cellvalue">
                        <NumberField record={record} basePath={basePath} source="def_months" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey">
                    <Typography color="inherit">Életkor:</Typography>
                    </div>
                    <div class="cellvalue">
                        <NumberField record={record} basePath={basePath} source="def_age" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey">
                    <Typography color="inherit">Megtakarítás:</Typography>
                    </div>
                    <div class="cellvalue">
                        <NumberField record={record} basePath={basePath} source="def_savings" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey">
                    <Typography color="inherit">Hitelösszeg:</Typography>
                    </div>
                    <div class="cellvalue">
                        <NumberField record={record} basePath={basePath} source="def_sum" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey">
                    <Typography color="inherit">Csak lista (nincs kalkulátor):</Typography>
                    </div>
                    <div class="cellvalue">
                        <ZeroOneBooleanField record={record} basePath={basePath} source="listing_only" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey">
                    <Typography color="inherit">Nincs kiemelés:</Typography>
                    </div>
                    <div class="cellvalue">
                        <ZeroOneBooleanField record={record} basePath={basePath} source="no_featured" />
                    </div>
                </div>
            </div>
        </>
    );
}

const CatMapFormTab1Label = "Alap beállítások";
const CatMapFormTab2Label = "További beállítások";

export const CatMapCreate = props => (
    <Create {...props} undoable={false} >
        <TabbedForm redirect="show" submitOnEnter={false} >
            <FormTab label={CatMapFormTab1Label}>
                <BasicSettings />
            </FormTab>
            <FormTab label={CatMapFormTab2Label}>
                <AdvancedSettings />
            </FormTab>
        </TabbedForm>
    </Create>
);

export const CatMapEdit = props => (
    <Edit {...props} undoable={false} >
        <TabbedForm redirect="show" submitOnEnter={false} >
            <FormTab label={CatMapFormTab1Label}>
                <BasicSettings />
            </FormTab>
            <FormTab label={CatMapFormTab2Label}>
                <AdvancedSettings />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export const CatMapShow = props => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label={CatMapFormTab1Label}>
                <BasicShowSettings />
            </Tab>
            <Tab label={CatMapFormTab2Label}>
                <AdvancedShowSettings />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export const CatMapList = props => (
    <List {...props} bulkActions={false} exporter={false} pagination={<CategoryPagination />} filters={<CatMapFilter />} title="Kategóriák" >
        <Datagrid rowClick="edit">
            <TextField label="URL" source="url" />
            <TextField label="Jövedelem" source="def_income" />
            <TextField label="Hitelösszeg" source="def_sum" />
            <TextField label="Futamidő (hó)" source="def_months" />
            <TextField label="Megtakarítás" source="def_savings" />
        </Datagrid>
    </List>
);
