import React from 'react';
import {
    Show,
    ReferenceField,
    ReferenceArrayField,
    SimpleShowLayout,
    SingleFieldList,
    TextField,
    SelectField,
    ChipField,
    ImageField,
} from 'react-admin';
import Typography from '@material-ui/core/Typography';

const BannerFields = ({ record, basePath }) => {
    return (
        <>
            <div class="table">
                <div class="row">
                    <div class="cellkey">
                        <Typography color="inherit">Hirdető:</Typography>
                    </div>
                    <div class="cellvalue">
                    <ReferenceField record={record} basePath={basePath} source="user_id" reference="users" linkType={false}>
                        <TextField source="name" />
                    </ReferenceField>
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey">
                        <Typography color="inherit">Hirdetés típusa:</Typography>
                    </div>
                    <div class="cellvalue">
                        <TextField record={record} basePath={basePath} source="banner_type" />
                    </div>
                </div>

                <div class="row">
                    <div class="cellkey">
                        <Typography color="inherit">Elnevezés:</Typography>
                    </div>
                    <div class="cellvalue">
                        <TextField record={record} basePath={basePath} source="name" />
                    </div>
                </div>

                <div class="row">
                    <div class="cellkey">
                        <Typography color="inherit">Státusz:</Typography>
                    </div>
                    <div class="cellvalue">
                    <SelectField record={record} basePath={basePath} source="status" choices={[
                        {status: 0, name: "felfüggesztett" },
                        {status: 1, name: "aktív" },
                        {status: 2, name: "lezárt" },
                        {status: 3, name: "törölt (véglegesen törölve)" },
                    ]} optionText="name" optionValue="status" />
                    </div>
                </div>

                    { record.banner_type === 2 ? (
                        <>
                            <div class="row">
                                <div class="cellkey">
                                    <Typography color="inherit">Mini banner színe (6 karakterű színkód):</Typography>
                                </div>
                                <div class="cellvalue">
                                    <TextField record={record} basePath={basePath} label="" source="color" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="cellkey">
                                    <Typography color="inherit">Mini banner szöveg alul:</Typography>
                                </div>
                                <div class="cellvalue">
                                    <TextField record={record} basePath={basePath} label="" source="title" />
                                </div>
                            </div>
                        </>
                    ) : null }

                { record.link_wrap === 1 ? (
                    <>
                        <div class="row">
                            <div class="cellkey">
                                <Typography color="inherit">Feltöltött bannerek:</Typography>
                            </div>
                            <div class="cellvalue">
                                <ImageField record={record} basePath={basePath}  source="bannerimgs" src="url" title="File" />
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div class="row">
                            <div class="cellkey">
                                <Typography color="inherit">HTML Banner:</Typography>
                            </div>
                            <div class="cellvalue">
                                <TextField record={record} basePath={basePath} label="" source="banner" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="cellkey">
                                <Typography color="inherit">HTML Mobil Banner:</Typography>
                            </div>
                            <div class="cellvalue">
                                <TextField record={record} basePath={basePath} label="" source="banner_mobile" />
                            </div>
                        </div>
                    </>
                ) }
                {record.banner_type === 1 ? (
                    <>
                        <div class="row">
                            <div class="cellkey">
                                <Typography color="inherit">Kategóriák (ha üres, mindenhol megjelenik):</Typography>
                            </div>
                            <div class="cellvalue">
                                <ReferenceArrayField record={record} basePath={basePath} source="type" reference="categories" linkType={false}>
                                    <SingleFieldList linkType={false}>
                                        <ChipField source="name" />
                                    </SingleFieldList>
                                </ReferenceArrayField>
                            </div>
                        </div>

                        <div class="row">
                            <div class="cellkey">
                                <Typography color="inherit">Kategória kizárása (ezekben nem fog megjelenni):</Typography>
                            </div>
                            <div class="cellvalue">
                                <ReferenceArrayField record={record} basePath={basePath} source="not_in_type" reference="categories" linkType={false}>
                                    <SingleFieldList linkType={false}>
                                        <ChipField source="name" />
                                    </SingleFieldList>
                                </ReferenceArrayField>
                            </div>
                        </div>
                </>) : null }

                <div class="row">
                    <div class="cellkey">
                        <Typography color="inherit">Hirdetés helye:</Typography>
                    </div>
                    <div class="cellvalue">
                        <ReferenceArrayField record={record} basePath={basePath} source="zones" reference="banner/zones" linkType={false}>
                            <SingleFieldList linkType={false}>
                                <ChipField source="name" />
                            </SingleFieldList>
                        </ReferenceArrayField>
                    </div>
                </div>

                <div class="row">
                    <div class="cellkey">
                        <Typography color="inherit">Link (expressz):</Typography>
                    </div>
                    <div class="cellvalue">
                        <TextField record={record} basePath={basePath} source="href_1" />
                    </div>
                </div>

                <div class="row">
                    <div class="cellkey">
                        <Typography color="inherit">Link (akolcson):</Typography>
                    </div>
                    <div class="cellvalue">
                        <TextField record={record} basePath={basePath} source="href_2" />
                    </div>
                </div>

                <div class="row">
                    <div class="cellkey">
                        <Typography color="inherit">Link (banknavigator):</Typography>
                    </div>
                    <div class="cellvalue">
                        <TextField record={record} basePath={basePath} source="href_4" />
                    </div>
                </div>

                <div class="row">
                    <div class="cellkey">
                        <Typography color="inherit">Menü Link (expressz):</Typography>
                    </div>
                    <div class="cellvalue">
                        <TextField record={record} basePath={basePath} source="href_menu_1" />
                    </div>
                </div>

                <div class="row">
                    <div class="cellkey">
                        <Typography color="inherit">Menü Link (akolcson):</Typography>
                    </div>
                    <div class="cellvalue">
                        <TextField record={record} basePath={basePath} source="href_menu_2" />
                    </div>
                </div>

                <div class="row">
                    <div class="cellkey">
                        <Typography color="inherit">Menü Link (banknavigator):</Typography>
                    </div>
                    <div class="cellvalue">
                        <TextField record={record} basePath={basePath} source="href_menu_4" />
                    </div>
                </div>

                <div class="row">
                    <div class="cellkey">
                        <Typography color="inherit">Mobil Link (expressz):</Typography>
                    </div>
                    <div class="cellvalue">
                        <TextField record={record} basePath={basePath} source="href_mobile_1" />
                    </div>
                </div>

                <div class="row">
                    <div class="cellkey">
                        <Typography color="inherit">Mobil Link (akolcson):</Typography>
                    </div>
                    <div class="cellvalue">
                        <TextField record={record} basePath={basePath} source="href_mobile_2" />
                    </div>
                </div>

                <div class="row">
                    <div class="cellkey">
                        <Typography color="inherit">Mobil Link (banknavigator):</Typography>
                    </div>
                    <div class="cellvalue">
                        <TextField record={record} basePath={basePath} source="href_mobile_4" />
                    </div>
                </div>


                <div class="row">
                    <div class="cellkey">
                        <Typography color="inherit">Kezdete:</Typography>
                    </div>
                    <div class="cellvalue">
                        <TextField record={record} basePath={basePath} source="start_time" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey">
                        <Typography color="inherit">Lejárata:</Typography>
                    </div>
                    <div class="cellvalue">
                        <TextField record={record} basePath={basePath} source="end_time" />
                    </div>
                </div>
            </div>
        </>
    );
}

export const BannerShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <BannerFields />
        </SimpleShowLayout>
    </Show>
)
