import React from 'react';
import {
    Edit,
    List,
    Datagrid,
    TextField,
    Filter,
    ReferenceInput,
    SelectInput,
    Pagination,
    SimpleForm,
    ReferenceField,
    TextInput,
    SaveButton,
    Toolbar,
    FormDataConsumer,
    required,
} from 'react-admin';

import Typography from '@material-ui/core/Typography';
import DeleteWithConfirmButton from '../button/DeleteWithConfirmButton';

const PromoToolbar = props => (
    <Toolbar {...props} >
        <SaveButton label="Mentés" redirect="list" submitOnEnter={false} />
        <DeleteWithConfirmButton label="Törlés"  />
    </Toolbar>
);

const PromoTitle = ({ record }) => {
    var newPozi = 5 - record.featured;

    return (
        <span>{record ? `Promóció beállítása a ${newPozi}. pozícióra` : ''}</span>
    );

};
const PromoPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />

const PromoTermekNeve = ({ record }) => {

    return (
        <span>{record.id} - {record.title} (#{record.users.name})</span>
    );
}

const PozicioFix = ({ record }) => {
    var newPozi = 5 - record.featured;

    return (
        <Typography component="span" body1="body1"> {newPozi} </Typography>
    );
};


const PromoFilter = props => (
    <Filter {...props}>
        <SelectInput label="Hirdető oldal" source="site_id" choices={[
                   { id: '1', name: 'expresszkolcson.hu' },
                   { id: '2', name: 'akolcson.hu' },
                   { id: '4', name: 'banknavigator.hu' },
            ]} alwaysOn allowEmpty={false} />
        <ReferenceInput label="Kategória" source="id" reference="categories_list" allowEmpty={false} alwaysOn >
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>

);

export const PromoList = props => (
    <List {...props} filters={<PromoFilter />} bulkActions={false} exporter={false} pagination={<PromoPagination />} filterDefaultValues={{ site_id: "1", id: 0 }} title="Promóciók" >
        <Datagrid rowClick="edit">
            <PozicioFix label="Pozíció" />
            <ReferenceField label="Hirdető" source="user_id" reference="users" linkType={false} allowEmpty>
                <TextField source="name" />
            </ReferenceField>
            <TextField label="Hirdetés" source="title" />
            <TextField label="Kiemelés Szövege" source="featured_text" />
        </Datagrid>
    </List>

);

export const PromoEdit = props => (
    <Edit {...props} title={<PromoTitle />} >
        <SimpleForm toolbar={<PromoToolbar />}>
            <ReferenceInput label="Fő Kategória" source="category_id" reference="categories_list" validate={ required() } sort={{ field: `name`, order: `ASC` }} >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <>
                        <ReferenceInput label="Hirdetés" source="ad_id" reference="ads" allowEmpty perPage={100} filter={{ status: 1, category_id: formData.category_id }} {...rest} >
                            <SelectInput optionText={<PromoTermekNeve />} />
                        </ReferenceInput>
                    </>
                );
            }}
            </FormDataConsumer>
            <ReferenceField label="Hirdető oldal" source="site_id" reference="sites" linkType={false}  >
                <TextField source="name" />
            </ReferenceField>
            <TextInput label="Kiemelés Szövege" source="featured_text" />
        </SimpleForm>
    </Edit>
);
