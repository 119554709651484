import React, { Fragment } from 'react';
import {
    List,
    Show,
    Datagrid,
    TextField,
    SelectField,
    EmailField,
    ReferenceField,
    ArrayField,
    Pagination,
    SimpleShowLayout,
    NumberField,
    Labeled,
    Filter,
    TextInput,
    downloadCSV,
} from 'react-admin';

import FullNameField from '../../field/FullNameField';
import { ZeroOneBooleanField } from '../../field/bool';
import BulkCRMResyncButton from '../../button/BulkCRMResyncButton';
import BulkDeleteWithConfirmButton from '../../button/BulkDeleteWithConfirmButton';
import ResendButton from '../../button/ResendButton';
import { SiteName } from '../../field/Site';
import { styles, hitelnetStatuses, Sites } from '../../constants';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import * as _ from 'lodash';

const CRMPagination = props => <Pagination rowsPerPageOptions={[ 10, 25, 50, 100]} {...props} />

const CRMBulkActionButtons = ( props ) => (
    <Fragment>
        <BulkCRMResyncButton {...props} />
        <BulkDeleteWithConfirmButton {...props} />
    </Fragment>
);

const CRMFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Telefonszám keresése" source="phone" alwaysOn/>
        <TextInput label="Vezetéknév keresése" source="lastname" alwaysOn/>
        <TextInput label="Keresztnév keresése" source="firstname" alwaysOn/>
    </Filter>
);

const ResendBank = ({ record }) => {
    var mySubmitted = record.is_submitted;

    return (
        <>
        { mySubmitted === 0 && <ResendButton record={record} resource="crm_hitelnet" target="bank" text="Újraküldés Banknak" />}
        </>
    );

};

const ExpandCRMShowDetails = ( props ) => {
    return (
        <>
                    <div style={styles.divTable}>
                        <div style={styles.divRow}>
                            <div style={styles.divCell}>
                                <Labeled label="Azonosító"><TextField label="Azonosító" source="id" record={props.record} /></Labeled>
                            </div>
                            <div style={styles.divCell}>
                                <Labeled label="CRM Azonosító"><TextField label="CRM Azonosító" source="remote_id" record={props.record} /></Labeled>
                            </div>
                            <div style={styles.divCell}>
                                <Labeled label="CRM Modul Azonosító"><TextField label="CRM Modul Azonosító" source="module_id" record={props.record} /></Labeled>
                            </div>
                        </div>
                        <div style={styles.divRow}>
                            <div style={styles.divCell}>
                                <Labeled label="Név"><FullNameField label="Név" record={props.record} /></Labeled>
                            </div>
                            <div style={styles.divCell}>
                                <Labeled label="Email"><EmailField source="email" record={props.record} /></Labeled>
                            </div>
                        </div>
                        <div style={styles.divRow}>
                            <div style={styles.divCell}>
                                <Labeled label="Igényelt Hitelösszeg"><NumberField source="misc.sum" record={props.record} /></Labeled>
                            </div>
                            <div style={styles.divCell}>
                                <Labeled label="Jövedelem"><NumberField source="misc.income" record={props.record} /></Labeled>
                            </div>
                            <div style={styles.divCell}>
                                <Labeled label="Futamidő"><NumberField source="misc.months" record={props.record} /></Labeled>
                            </div>
                            <div style={styles.divCell}>
                                <Labeled label="THM"><NumberField source="misc.thm" record={props.record} /></Labeled>
                            </div>
                            <div style={styles.divCell}>
                                <Labeled label="Törlesztő"><NumberField source="misc.installment" record={props.record} /></Labeled>
                            </div>
                        </div>
                        <div style={styles.divRow}>
                            <div style={styles.divCell}>
                                <Labeled label="Telefonszám"><NumberField label="Telefonszám" source="phone" record={props.record} /></Labeled>
                            </div>
                        </div>
                        <div style={styles.divRow}>
                            <div style={styles.divCell}>
                                <Labeled label="Bankba beküldve"><TextField source="submitted" label="Bankba beküldve" record={props.record} /></Labeled>
                            </div>
                            <div style={styles.divCell}>
                                <Labeled label="Létrehozás dátuma"><TextField source="created" label="Létrehozás dátuma" record={props.record} /></Labeled>
                            </div>
                        </div>
                    </div>
        </>
    );

};

const ExpandCRMShow = ( props ) => {
    return (
    <Show {...props}>
        <SimpleShowLayout>
            <ExpandCRMShowDetails />
            <ArrayField label="Lead státusz" source="leadstatus" record={props.record}>
                <Datagrid>
                    <SelectField label="Státusz" source="status" choices={hitelnetStatuses} optionText="name" optionValue="status" />
                    <TextField source="modified_at" label="Változás dátuma" />
                </Datagrid>
            </ArrayField>
            <ReferenceField label="Hirdetés" source="ad_id" reference="ads">
                <TextField source="title" />
            </ReferenceField>
        </SimpleShowLayout>
    </Show>
    );
};

const exporter = crm_hitelnet => {
    const hitelnetForExport = crm_hitelnet.map(crm => {
        const { is_screened, is_sent, is_submitted, misc, module_id, ...hitelnetForExport} = crm;
        const state = _.find(hitelnetStatuses, function(e) {
            if (e.status === crm.leadstatus[crm.leadstatus.length - 1].status) {
                return e.name;
            }
        });
        const site = _.find(Sites, function(e) {
            if (e.id === crm.site_id) {
                return e.name;
            }
        });
        hitelnetForExport.CRMID = crm.remote_id;
        hitelnetForExport.Vezetéknév = crm.lastname;
        hitelnetForExport.Keresztnév = crm.firstname;
        hitelnetForExport.Beküldve = crm.submitted;
        hitelnetForExport.Oldal = site.name;
        hitelnetForExport.Státusz = state.name;
        hitelnetForExport.Létrehozva = crm.created;
        hitelnetForExport.Státusz_módosítva = crm.leadstatus[crm.leadstatus.length - 1].modified_at;
        return hitelnetForExport;
    });
    const csv = convertToCSV({
        data: hitelnetForExport,
        fields: ['id', 'CRMID', 'Vezetéknév', 'Keresztnév', 'Létrehozva', 'Beküldve', 'Oldal', 'Státusz', 'Státusz_módosítva']
    });
    downloadCSV(csv, 'Hitelnet');
}


export const HitelnetList = props => (
    <List {...props} title="Hitelnet Leadek" filters={<CRMFilter />} exporter={exporter} pagination={<CRMPagination />} bulkActionButtons={<CRMBulkActionButtons />}>
        <Datagrid expand={<ExpandCRMShow />}>
            <TextField label="ID" source="id" />
            <TextField label="CRMID" source="remote_id" />
            <TextField label="Időpont" source="created" />
            <SiteName label="Oldal"/>
            <FullNameField label="Név" />
            <EmailField label="Email" source="email" />
            <ReferenceField label="Hirdetés" source="ad_id" reference="ads">
                <TextField source="title" />
            </ReferenceField>
            <TextField label="Telefonszám" source="phone" />
            <SelectField label="Státusz" source="last_status.status" choices={hitelnetStatuses} optionText="name" optionValue="status" />
            <ZeroOneBooleanField source="is_submitted" label="Bankba beküldve" />
            <ResendBank />
        </Datagrid>
    </List>
);
