import React from 'react';
import {
    List,
    Edit,
    Create,
    TextField,
    TextInput,
    FileInput,
    FileField,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    Filter,
    DeleteButton,
    EditButton,
    required,
} from 'react-admin';
import { Tree, NodeView, NodeActions } from 'ra-tree-ui-materialui';
import DownloadButton from '../../button/DownloadButton';

const OktatasFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Bank" source="bank_id" reference="users/banknames" allowEmpty={false} alwaysOn >
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

const OktatasActions = ({ permissions, ...props }) => (
    <NodeActions {...props}>
            <DownloadButton />
            {permissions === 'admin' && <EditButton />}
            {permissions === 'admin' && <DeleteButton />}
    </NodeActions>
);

export const FMOktatasList = ({ permissions, ...props}) => (
    <List {...props} perPage={10000} filters={<OktatasFilter />} filterDefaultValues={{ bank_id: 13 }} title="Oktatás" exporter={false}>
        <Tree>
                <NodeView actions={<OktatasActions permissions={permissions} />}>
                    <TextField source="name" />
                </NodeView>
        </Tree>
    </List>
);

export const FMOktatasEdit = (props) => (
        <Edit {...props}>
            <SimpleForm redirect="list">
                <TextInput source="name" validate={ required() } />
            </SimpleForm>
        </Edit>
);

export const FMOktatasCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <FileInput source="filecontent" label="Új oktató anyag feltöltése" multiple={false} >
                <FileField source="name" title="title" />
            </FileInput>
            <ReferenceInput label="Bank" source="bank_id" reference="users/banknames">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput label="Alkönyvtár" source="parent_id" reference="filemanager/subdirs">
                <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);
