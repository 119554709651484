import { UserList, UserShow, UserEdit, UserCreate } from './users';
import UserIcon from '@material-ui/icons/Group';

export default {
    list: UserList,
    show: UserShow,
    edit: UserEdit,
    create: UserCreate,
    icon: UserIcon,
};
