import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = {
        link: {
                    textDecoration: 'none',
                },
        icon: {
                    width: '0.5em',
                    paddingLeft: 2,
                },
};

const ClickIpField = ({ record = {}, source, ip, url, classes }) =>
    <a href={url} className={classes.link}>{ip}</a>;

export default withStyles(styles)(ClickIpField);
