import React from 'react';
import {
    Query,
} from 'react-admin';

const LastRun = ({ resource }) => (
        <Query type="GET_ONE" resource={resource} >
        {({ data, loading, error }) => {
                    if (loading) { return <p>Loading... </p>; }
                    if (error) { return <p>ERROR</p>; }
                    return (
                                    <div>
                                        <p align="right">Utolsó bankbaküldés időpontja: {data.last_run}</p>
                                    </div>

                                );
                }}
        </Query>
);

export default LastRun;
