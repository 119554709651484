import React from 'react';
import PropTypes from 'prop-types';
import shouldUpdate from 'recompose/shouldUpdate';
import ContentCreate from '@material-ui/icons/Create';
import { Link } from 'react-router-dom';
import { linkToRecord } from 'ra-core';

import { Button } from 'react-admin';

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = e => e.stopPropagation();

const AdsEditButton = ({
    basePath = '',
    label = 'ra.action.edit',
    record = {},
    icon = <ContentCreate />,
    locateUrl,
    tabID,
    ...rest
}) => {
    let target_url = linkToRecord(basePath, record.id, locateUrl);
    if (tabID !== undefined && tabID !== '')
        target_url += '/'+tabID;

    return (
    <Button
        component={Link}
        to={target_url}
        label={label}
        onClick={stopPropagation}
        {...rest}
    >
        {icon}
    </Button>
    );
};

AdsEditButton.propTypes = {
    basePath: PropTypes.string,
    className: PropTypes.string,
    classes: PropTypes.object,
    label: PropTypes.string,
    record: PropTypes.object,
    icon: PropTypes.element,
    tabID: PropTypes.string,
};

const enhance = shouldUpdate(
    (props, nextProps) =>
        props.translate !== nextProps.translate ||
        (props.record &&
            nextProps.record &&
            props.record.id !== nextProps.record.id) ||
        props.basePath !== nextProps.basePath ||
        (props.record == null && nextProps.record != null)
);

export default enhance(AdsEditButton);
