import React from 'react';
import {
    Edit,
    TextInput,
    TextField,
    ReferenceInput,
    SelectInput,
    SimpleForm,
} from 'react-admin';

const ProductTitle = ({ record }) => {
    return <span>{record ? `${record.title}` : ''}</span>;
};

export const ProductEdit = ({ ...props }) => (
    <Edit {...props} title={<ProductTitle />} >
        <SimpleForm redirect="show" submitOnEnter={false}>
            <TextField label="Azonosító" source="id" />
            <TextInput label="Termék neve" source="title" />
            <ReferenceInput label="Hirdető" source="user_id" reference="users" perPage={100} alwaysOn >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput label="Kategória" source="category_id" reference="categories_list" >
                <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);