import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';


const Preferences = () => (
    <Card>
            <Title title="Beállítások" />
            <CardContent>
                ...
            </CardContent>
        </Card>
);

export default Preferences;
