import React from 'react';
import {
    TextInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    Create,
} from 'react-admin';

export const ProductCreate = ({ permissions, ...props }) => (
    <Create {...props} >
        <SimpleForm redirect="show" submitOnEnter={false}>
            <TextInput label="Termék neve" source="title" validate={ required() } />
            <ReferenceInput label="Hirdető" source="user_id" reference="users" perPage={100} alwaysOn >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput label="Kategória" source="category_id" reference="categories_list" >
                <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);