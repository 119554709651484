import React from 'react';
import {
    List,
    Edit,
    Create,
    Datagrid,
    TextField,
    TextInput,
    SimpleForm,
    Pagination,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    EditButton,
    DeleteButton,
} from 'react-admin';

const CategoryPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />

const CategoryParent = ({ record, basePath }) => {
    if (typeof record.parent_id !== "number") {
        return (<TextField record={record} source="name" />);
    } else {
        return ( 
                <ReferenceField record={record} basePath={basePath} source="parent_id" reference="categories" linkType={false}>
                    <TextField record={record} source="name" />
                </ReferenceField>
        );
    }
}

export const CategoryCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list" submitOnEnter={false}>
            <ReferenceInput source="parent_id" reference="categories_main" allowEmpty linkType={false}>
                <SelectInput name="name" />
            </ReferenceInput>
            <TextInput source="name" label="Kategória" />
        </SimpleForm>
    </Create>
)
//            <ReferenceInput source="parent_id" reference="categories" filter={{ parent_id: notnull }}>

export const CategoryEdit = props => (
    <Edit {...props} undoable={false} >
        <SimpleForm redirect="show" submitOnEnter={false} >
            <ReferenceInput source="parent_id" reference="categories_main" allowEmpty linkType={false}>
                <SelectInput name="name" />
            </ReferenceInput>
            <TextInput source="name" label="Kategória" />
        </SimpleForm>
    </Edit>
)

export const CategoryList = props => (
    <List {...props} bulkActions={false} exporter={false} pagination={<CategoryPagination />} title="Kategóriák" >
        <Datagrid>
            <CategoryParent label="Főkategória" />
            <TextField label="Kategória" source="name" />
            <TextField label="ID" source="id" />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);