import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    Pagination,
    Filter,
    SelectInput,
    ReferenceField,
} from 'react-admin';

import FullNameField from '../../field/FullNameField';
import { CRMModuleData } from '../../constants';

var myDate = new Date();
var thisYear = myDate.getFullYear();

var years = [];
for ( let filterYear = 2016; filterYear <= thisYear; filterYear++) {
    years.push({ id: filterYear, name: filterYear});
}

const CRMFilter = (props) => (
    <Filter {...props}>
        <SelectInput label="CRM Modul" source="module_id" choices={CRMModuleData} alwaysOn />
        <SelectInput label="Év" source="year" choices={years} translateChoice={false} alwaysOn />
    </Filter>
);

const CRMPagination = props => <Pagination rowsPerPageOptions={[ 10, 25, 50, 100]} {...props} />

export const CRMList = props => (
    <List {...props} title="CRM lista" pagination={<CRMPagination />} filterDefaultValues={{ year: thisYear, module_id: "35" }} filters={<CRMFilter />} bulkActions={false} >
        <Datagrid>
            <FullNameField label="Név" />
            <TextField label="Bank címe" source="address" />
            <TextField source="time" label="Bankba beküldve" />
            <ReferenceField label="Termék neve" source="ad_id" reference="ads" linkType={false}>
                <TextField source="title" />
            </ReferenceField>
        </Datagrid>
    </List>
);
