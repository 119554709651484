import React, { Component } from 'react';
import { Admin, Resource, mergeTranslations } from 'react-admin';

import authProvider from './authprovider/authProvider';
import { Login, Layout }  from './layout';
//import { Login, Layout, NotFound }  from './layout';
import customRoutes from './customRoutes/customRoutes';
import { Dashboard } from './dashboard';
import { reducer as tree } from 'ra-tree-ui-materialui';
import englishMessages from 'ra-language-english';
import hungarianMessages from 'ra-language-hungarian';
import treeEnglishMessages from 'ra-tree-language-english';

import finex from './leads/finex/';
import mkb from './leads/mkb/';
import mkb_vallalkozoi from './leads/mkb_vallalkozoi/';
import cityleasing from './leads/cityleasing/';
import takarekbank from './leads/takarekbank/';
import unicredit from './leads/unicredit/';
import hitelnet from './leads/hitelnet/';
import kh from './leads/kh/';
import erste from './leads/erste/';
import minikolcson from './leads/minikolcson/';
import sberbank from './leads/sberbank/';
import crmlist from './leads/list/';
import users from './users/';
import promo from './promo/';
import promo_calc from './promo_calc/';
import ads from './ads/';
import oktatas from './filemanager/oktatas/';
import nyomtatvanyok from './filemanager/nyomtatvanyok/';
import news from './filemanager/news/';
import product from './product/';
import auditlog from './auditlog';
import banner from './banner';
import bannerclicks from './bannerclicks';
import categories from './categories';
import catmap from './catmap';
import tags from './tags';
import clicks from './clicks';


import dataProviderFactory from './dataprovider';

const messages = {
        'hu': hungarianMessages,
        'en': mergeTranslations(englishMessages, treeEnglishMessages),
};

const i18nProvider = locale => messages[locale];

class App extends Component {
    state = { dataProvider: null };

    async componentWillMount() {
        const dataProvider = await dataProviderFactory('simplerest');

        this.setState({ dataProvider });
    }

    componentWillUnmount() {
        this.restoreFetch();
    }

    render() {
        const { dataProvider } = this.state;
        if (!dataProvider) {
            return (
                <div className="loader-container">
                <div className="loader">Loading...</div>
                </div>
            );
        }
//catchAll={NotFound}
        return (
            <Admin customRoutes={customRoutes} loginPage={Login} dashboard={Dashboard} authProvider={authProvider} dataProvider={dataProvider} appLayout={Layout} locale="hu" messages={messages} i18nProvider={i18nProvider} customReducers={{ tree }}>
            {permissions => [
                <Resource name="ads" options={{ label: 'Hirdetések' }} {...ads} />,
                permissions === 'admin' ? <Resource name="ads-featured" options={{ label: 'Promóciók' }} {...promo} /> : null,
                permissions === 'admin' ? <Resource name="user2category" options={{ label: 'Promóciók Banknavigatorra' }} {...promo_calc} /> : null,
                permissions === 'admin' ? <Resource name="users" options={{ label: 'Felhasználók' }} {...users} /> : null,
                permissions === 'admin' ? <Resource name="leads" options={{ label: 'Finex leadek' }} {...finex} /> : null,
                permissions === 'admin' ? <Resource name="crm_list" options={{ label: 'CRM Lista' }} {...crmlist} /> : null,
                permissions === 'admin' ? <Resource name="crm_sberbank" options={{ label: 'Sberbank leadek' }} {...sberbank} /> : null,
                permissions === 'admin' ? <Resource name="crm_mbh" options={{ label: 'MKB leadek' }} {...mkb} /> : null,
                permissions === 'admin' ? <Resource name="crm_mbh_vallalkozoi" options={{ label: 'MKB Vállalkozói leadek' }} {...mkb_vallalkozoi} /> : null,
                permissions === 'admin' ? <Resource name="crm_cityleasing" options={{ label: 'City Leasing' }} {...cityleasing} /> : null,
                permissions === 'admin' ? <Resource name="crm_kh" options={{ label: 'K&H leadek' }} {...kh} /> : null,
                permissions === 'admin' ? <Resource name="crm_takarekbank" options={{ label: 'Takarékbank leadek' }} {...takarekbank} /> : null,
                permissions === 'admin' ? <Resource name="crm_unicredit" options={{ label: 'Unicredit leadek' }} {...unicredit} /> : null,
                permissions === 'admin' ? <Resource name="crm_hitelnet" options={{ label: 'Hitelnet leadek' }} {...hitelnet} /> : null,
                permissions === 'admin' ? <Resource name="crm_minikolcson" options={{ label: 'Minikolcson leadek' }} {...minikolcson} /> : null,
                permissions === 'admin' ? <Resource name="crm_erste" options={{ label: 'ERSTE leadek' }} {...erste} /> : null,
                permissions === 'admin' ? <Resource name="misc_products" options={{ label: 'További Terméknevek' }} {...product} /> : null,
                permissions === 'admin' ? <Resource name="auditlog" options={{ label: 'Audit Log' }} {...auditlog} /> : null,
                permissions === 'admin' ? <Resource name="banner" options={{ label: 'Bannerek' }} {...banner} /> : null,
                permissions === 'admin' ? <Resource name="tags" options={{ label: 'Tags' }} {...tags} /> : null,
                permissions === 'admin' ? <Resource name="banner-clicks" options={{ label: 'Banner Kattintások' }} {...bannerclicks} /> : null,
                permissions === 'admin' || permissions === 'kozvetito' ? <Resource name="filemanager_oktatas" options={{ label: 'Oktatás' }} {...oktatas} /> : null,
                permissions === 'admin' || permissions === 'kozvetito' ? <Resource name="filemanager_nyomtatvanyok" options={{ label: 'Banki nyomtatványok' }} {...nyomtatvanyok} /> : null,
                permissions === 'admin' || permissions === 'ujsagiro' ? <Resource name="filemanager_news" options={{ label: 'Újságírói felület' }} {...news} /> : null,
                permissions === 'admin' || permissions === 'hirdeto' ? <Resource name="categories" options={{ label: 'Kategóriák' }} {...categories} /> : null,
                permissions === 'admin' ? <Resource name="category_map" options={{ label: 'Kategória Map' }} {...catmap} /> : null,
                permissions === 'admin' ? <Resource name="clicks_report" options={{ label: 'CT Statisztika' }} {...clicks} /> : null,
                <Resource name="sites" />,
                <Resource name="user" />,
                <Resource name="ads-resync" />,
                <Resource name="ads-commit" />,
                <Resource name="ads-drop" />,
                <Resource name="crm-resync" />,
                <Resource name="crmlastrun_mbh" />,
                <Resource name="crmlastrun_unicredit" />,
                <Resource name="crmlastrun_takarekbank" />,
                <Resource name="branch/kh" />,
                <Resource name="branch/erste" />,
                <Resource name="branch/unicredit" />,
                <Resource name="usertype" />,
                <Resource name="filemanager/subdirs_oktatas" />,
                <Resource name="filemanager/subdirs_nyomtatvanyok" />,
                <Resource name="filemanager/subdirs_news" />,
                <Resource name="filemanager" />,
                <Resource name="users/banknames" />,
                <Resource name="subcategories" />,
                <Resource name="categories_list" />,
                <Resource name="categories_main" />,
                <Resource name="banner/zones" />
            ]}
            </Admin>

        );
    }
}

export default App;
