import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';
import Typography from '@material-ui/core/Typography';
import { SelectInput } from 'react-admin';
import { LeadFormData } from '../constants';

export const TargetInput = ({ record = {}, formData }) => {
    return (
        <SelectInput label="Lead Form" source="target_id" choices={LeadFormData} optionText="name" />
    );
};

const TargetField = ({ record = {} }) => {
    function myFunction(value, index, array) {
        if ( record.target_id === value.id ) {
            return true;
       }
    }

    const target = LeadFormData.find(myFunction) || { name: "" };
    return <Typography component="span" body1="body1"> {target.name} </Typography>;
};

TargetField.propTypes = {
    addLabel: PropTypes.bool,
    label: PropTypes.string,
    record: PropTypes.object,
};

TargetField.displayName = 'TargetField';
const PureTargetField = pure(TargetField);

PureTargetField.defaultProps = {
    addLabel: true,
};

export default PureTargetField;
