import React from 'react';
import {
    List,
    Show,
    Edit,
    Create,
    SimpleShowLayout,
    SimpleForm,
    Datagrid,
    TextField,
    TextInput,
    DisabledInput,
    EmailField,
    EditButton,
    Pagination,
    email,
    required,
    ReferenceInput,
    SelectInput,
} from 'react-admin';
//import BasicForms from '../field/from_director/BasicForms';
import PasswordInput from '../field/PasswordInput';

const UserPagination = props => <Pagination rowsPerPageOptions={[ 10, 25, 50, 100]} {...props} />

export const UserList = props => (
        <List {...props} bulkActions={false} title="Felhasználók"  pagination={<UserPagination />} exporter={false} >
            <Datagrid rowClick="show">
                <TextField label="Felhasználó ID" source="id" />
                <TextField label="Felhasználó neve" source="fullname" />
                <TextField label="Bejelentkezési név" source="name" />
                <EmailField label="E-mail cím" source="email" />
                <EditButton />
            </Datagrid>
        </List>
);

export const UserShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField label="Felhasználó ID" source="id" />
            <TextField label="Felhasználó neve" source="fullname" />
            <TextField label="Bejelentkezési név" source="name" />
            <TextField label="E-mail cím" source="email" />
            <TextField label="Telefonszám" source="tel" />
            <TextField label="Jogosultság" source="user_type.name" />
            <TextField label="Google Analytics ID" source="analytics" />
        </SimpleShowLayout>
    </Show>
);

export const UserEdit = props => (
        <Edit {...props}>
            <SimpleForm>
                <DisabledInput label="Felhasználó ID" source="id" />
                <TextInput label="Felhasználó neve" source="fullname" />
                <TextInput label="Bejelentkezési név" source="name" />
                <TextInput label="E-mail cím" source="email" />
                <PasswordInput label="Jelszó" source="pass" type="password" validate={ required() } />
                <TextInput label="Telefonszám" source="tel" validate={ required() } />
                <ReferenceInput label="Jogosultság" source="type" reference="usertype">
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <TextInput label="Google Analytics ID" source="analytics" />
            </SimpleForm>
        </Edit>
);

const validateEmail = [required(), email()];

export const UserCreate = props => (
        <Create {...props} title="Uj Felhasznalo">
            <SimpleForm redirect="list">
                    <TextInput label="Felhasználó neve" source="fullname" />
                    <TextInput label="Bejelentkezési név" source="name" validate={ required() } />
                    <TextInput label="E-mail cím" source="email" type="email" validate={validateEmail} />
                    <PasswordInput label="Jelszó" source="pass" type="password" validate={ required() } />
                    <TextInput label="Telefonszám" source="tel" validate={ required() } />
                    <ReferenceInput label="Jogosultság" source="type" reference="usertype" validate={ required() }>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <TextInput label="Google Analytics ID" source="analytics" />
                </SimpleForm>
        </Create>
);

