import React from 'react';
import { Layout, Sidebar } from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';
import Notification from './Notification';

const CustomSidebar = (props) => <Sidebar {...props} size={250} />;
const CustomLayout = (props) => <Layout {...props} appBar={AppBar} sidebar={CustomSidebar} menu={Menu} notification={Notification} />;

export default CustomLayout;
