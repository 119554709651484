import React, { Fragment } from 'react';
import {
    List,
    Show,
    Edit,
    BulkDeleteButton,
    Datagrid,
    ReferenceField,
    EmailField,
    TextField,
    NumberField,
    Pagination,
    SimpleShowLayout,
    SimpleForm,
    DisabledInput,
    TextInput,
    LongTextInput,
    NumberInput,
    ReferenceInput,
    SelectInput,
    Filter,
} from 'react-admin';
import { IpConverter } from '../../field/IpConverter';
import FullNameField from '../../field/FullNameField';
import TargetField from '../../field/TargetField';
//import DateOnly from '../field/DateOnly';
import { SiteName } from '../../field/Site';
import ResendButton from '../../button/ResendButton';
import BulkResendButton from '../../button/BulkResendButton';
import { ZeroOneBooleanField } from '../../field/bool';

const LeadPagination = props => <Pagination rowsPerPageOptions={[ 10, 25, 50, 100 ]} {...props} />

const LeadTitle = ({ record }) => {
    return <span>Lead {record ? `"${record.id}"` : ''}</span>;
};

const LeadFilter = (props) => (
            <Filter {...props}>
                <TextInput label="Telefonszám keresése" source="phone" />
                <TextInput label="Név keresése" source="lastname" />
                <ReferenceInput label="Hirdető oldal" source="site_id" reference="sites" >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <SelectInput label="Beküldve" source="is_submitted" choices={[
                    {id: 0, name: "Nem beküldött"},
                    {id: 1, name: "Beküldött"},
                ]} />
            </Filter>
);

const LeadBulkActionButtons = props => (
            <Fragment>
                <BulkResendButton {...props} />
                <BulkDeleteButton {...props} undoable={false} />
            </Fragment>
);

const ResendCRMButton = ({ record }) => {
    return (
        <>
        { record.remote_id == null && <ResendButton record={record} resource="leads" target="minicrm" text="CRM" /> }
        </>
    );
};


export const LeadList = props => (
        <List {...props} title="Finex Leadek" bulkActionButtons={<LeadBulkActionButtons />} pagination={<LeadPagination />} exporter={false} filters={<LeadFilter />}>
            <Datagrid expand={<ExpandLeadEdit />}>
                <TextField label="Időpont" source="created" />
                <TargetField source="target_id" label="Típus" />
                <SiteName label="Oldal"/>
                <TextField source="lastname" label="Név" />
                <TextField label="Telefon" source="phone" />
                <EmailField label="Email" source="email" />
                <ReferenceField label="Hirdetés" source="ad_id" reference="ads"><TextField source="title" /></ReferenceField>
           	    <NumberField label="Irányitószám" source="zip_code" />
                <TextField label="Összeg" source="misc.amount" />
                <TextField label="Jövedelem" source="misc.jovedelem_text" />
                <ZeroOneBooleanField label="Beküldve" source="submitted" />
                <ResendButton resource="leads" target="finex" text="Finex" />
                <ResendCRMButton />
            </Datagrid>
        </List>
);

const ExpandLeadEdit = props => (
    <Edit
        {...props} undoable={false}
        /* disable the app title change when shown */
        title=" "
    >
        <SimpleForm
            /* The form must have a name dependent on the record, because by default all forms have the same name */
            form={`lead_edit_${props.id}`}
        >
           <DisabledInput label="#" source="id" /><DisabledInput label="Időpont" source="created" />
           <ReferenceInput label="Oldal" source="site_id" reference="sites" >
              <SelectInput optionText="name" />
           </ReferenceInput>
           <TextInput label="Vezetéknév" source="lastname" resettable />
           <TextInput label="Keresztnév" source="firstname" resettable />
           <TextInput label="Telefon" source="phone" resettable />
           <TextInput label="Email" source="email" resettable />
           <NumberInput label="Irányitószám" source="zip_code" />
           <TextInput label="Összeg" source="misc.amount" />
           <TextInput label="Jövedelem" source="misc.jovedelem_text" />
           <LongTextInput label="Megjegyzés" source="misc.message" />
           <ReferenceField label="Hirdetés" source="ad_id" reference="ads"><TextField source="title" /></ReferenceField>
           <IpConverter label="IP" source="ip" />
        </SimpleForm>
    </Edit>
);

export const LeadShow = props => (
    <Show {...props} title={<LeadTitle />}>
        <SimpleShowLayout>
           <TextField label="#" source="id" />
           <TextField label="Időpont" source="created" />
           <SiteName label="Oldal"/>
           <FullNameField label="Név" />
           <TextField label="Telefon" source="phone" />
           <EmailField label="Email" source="email" />
           <NumberField label="Irányitószám" source="zip_code" />
           <ReferenceField label="Hirdetés" source="ad_id" reference="ads"><TextField source="title" /></ReferenceField>
           <TextField label="Összeg" source="misc.amount" />
           <TextField label="Jövedelem" source="misc.jovedelem_text" />
           <IpConverter label="IP" source="ip"  />
        </SimpleShowLayout>
    </Show>
);

export const LeadEdit = props => (
    <Edit {...props} title={<LeadTitle />}>
        <SimpleForm>
           <DisabledInput label="#" source="id" />
           <DisabledInput label="Időpont" source="created" />
           <ReferenceInput label="Oldal" source="site_id" reference="sites" >
              <SelectInput optionText="name" />
           </ReferenceInput>
           <TextInput label="Vezetéknév" source="lastname" resettable />
           <TextInput label="Keresztnév" source="firstname" resettable />
           <TextInput label="Telefon" source="phone" resettable />
           <TextInput label="Email" source="email" resettable />
           <NumberInput label="Irányitószám" source="zip_code" />
           <ReferenceField label="Hirdetés" source="ad_id" reference="ads"><TextField source="title" /></ReferenceField>
           <IpConverter label="IP" source="ip" />
        </SimpleForm>
    </Edit>
);
