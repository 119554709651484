import React from 'react';
import { Labeled } from 'react-admin';
import ClickIpField from './ClickIpField';

var ip = require('ip');

export const IpConverter = ({ record = {}, source, label, list }) => {
        var value = ip.fromLong(record.ip);
        var url = `https://geoiptool.com/hu/?ip=${value}`;
        var command;
        if (list) {
            command = <ClickIpField source="ip" ip={value} url={url} />;
        } else {
            command = <Labeled label={label}><ClickIpField source="ip" ip={value} url={url} /></Labeled>;
        }
        return  command;
};

