import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import Backup from '@material-ui/icons/Backup';
import { Button, Confirm, crudUpdateMany } from 'react-admin';

class BulkCRMResyncButton extends Component {
    state = {
        isOpen: false,
    }

    handleConfirm = () => {
        const { basePath, crudUpdateMany, resource, selectedIds } = this.props;
        crudUpdateMany( resource, selectedIds, { resync: true }, basePath );
        this.setState({ isOpen: true });
    };

    handleDialogClose = () => {
        this.setState({ isOpen: false });
    };

    handleClick = () => {
        this.setState({ isOpen: true });
    }

    render() {
        return (
            <Fragment>
                <Button label="Újratöltés" onClick={this.handleClick}><Backup /></Button>
                <Confirm
                    isOpen={this.state.isOpen}
                    title="Újratöltés"
                    content="Szeretnéd újratölteni?"
                    cancel="Nem"
                    confirm="Igen"
                    onConfirm={this.handleConfirm}
                    onClose={this.handleDialogClose}
                />
            </Fragment>
        );
    }
}

export default connect(undefined, { crudUpdateMany })(BulkCRMResyncButton);
