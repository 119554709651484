import React from 'react';
import {
    Create,
    SimpleForm,
} from 'react-admin';
import { BannerFields } from './BannerInputs';

export const BannerCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="show" submitOnEnter={false}>
            <BannerFields />
        </SimpleForm>
    </Create>
)