import { BannerList } from './BannerList';
import { BannerShow } from './BannerShow';
import { BannerEdit } from './BannerEdit';
import { BannerCreate } from './BannerCreate';
import BannerIcon from '@material-ui/icons/Book';

export default {
    list: BannerList,
    edit: BannerEdit,
    show: BannerShow,
    create: BannerCreate,
    icon: BannerIcon,
};
