import React from 'react';
import {
    Create,
    TextInput,
    TextField,
    DateInput,
    TabbedForm,
    FormTab,
    ReferenceArrayInput,
    SelectArrayInput,
    LongTextInput,
    required,
    FormDataConsumer,
    SelectInput,
    ArrayInput,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { MyStatus, MyUser, MyClickType } from '../field/customAdsFields';
import { TargetInput } from '../field/TargetField';
import { styles, findLabels, findDataFields, findRatesFields } from '../constants';
import { CreationBanner } from './Banner';
import { SimpleFormIterator2 } from '../field/SimpleFormIterator';

export const AdCreate = ({ permissions, ...props }) => {
    return (
    <Create {...props} >
        <TabbedForm submitOnEnter={false} redirect="list" >
            <FormTab label="Alapadatok">
                <CreationBanner />
                <TextField label="Azonosító" source="id" />
                <TextInput label="Termék neve" source="title" validate={ required() } />
        {permissions === 'admin' && <TextInput label="Beküldési név, amennyiben külünbözik a termék nevétől (pl. Sberbank)" source="crm_name" className="label-top" />}
    {permissions === 'admin' ? <MyStatus admin={true} /> : <MyStatus /> }
    {permissions === 'admin' ? <MyUser admin={true} /> : <MyUser /> }
    {permissions === 'admin' ? <MyClickType admin={true} /> : <MyClickType /> }
    <FormDataConsumer>
    {({ formData, ...rest }) => {
        return (
            <> {formData.click_type && <TargetInput formData={formData.click_type} {...rest} />}</>
        );
    }}
    </FormDataConsumer>
    <SelectInput label="Minősített fogyasztóbarát hitel?" source="logo_display" choices={[
                    { id: 0, name: "Nem" },
                    { id: 1, name: "Igen" },
                ]} optionText="name" optionValue="id" defaultValue={0} />
    </FormTab>
            <FormTab label="Jellemzők">
                <ReferenceArrayInput label="Kategóriák" source="categories" reference="categories_list" perPage={100} validate={ required() } >
                    <SelectArrayInput optionText="name" />
                </ReferenceArrayInput>
        <TextInput label="Hirdetmény URL" source="proclamation_url" />
        <TextInput label="Hirdetmény Szöveg" source="proclamation_text" />
        <FormDataConsumer>
        {({formData, ...rest}) => {
            const dataLabels = findLabels(formData.categories);

            var label3 = dataLabels.label3 || "";
            var kieg = dataLabels ? "Kiegészítő adat" : "";
            var kieg3 = dataLabels.label3 ? "Kiegészítő adat" : "";

            return (
                <>
                <div style={styles.divTable}>
                    <div style={styles.divRow}>
                        <div style={styles.divCell}>
                            { dataLabels.label1 && <TextInput source="data_1" label={dataLabels.label1} validate={ required() } /> }
                        </div>
                        <div style={styles.divCell}>
                            { kieg && <TextInput source="data_2" label={kieg} /> }
                        </div>
                    </div>
                    <div style={styles.divRow}>
                        <div style={styles.divCell}>
                            { dataLabels.label2 && <TextInput source="data_3" label={dataLabels.label2} /> }
                        </div>
                        <div style={styles.divCell}>
                            { kieg && <TextInput source="data_4" label={kieg} /> }
                        </div>
                    </div>
                    <div style={styles.divRow}>
                        <div style={styles.divCell}>
                            { label3 && <TextInput source="data_5" label={label3} /> }
                        </div>
                        <div style={styles.divCell}>
                            { kieg3 && <TextInput source="data_6" label={kieg3} /> }
                        </div>
                    </div>
                </div>
                </>
            )
        }}
        </FormDataConsumer>
                <LongTextInput source="spec_1" label="Termék Jellemzők 1" validate={ required() } />
                <LongTextInput source="spec_2" label="Termék Jellemzők 2" />
                <LongTextInput source="spec_3" label="Termék Jellemzők 3" />
                <LongTextInput source="spec_4" label="Termék Jellemzők 4" />
                <LongTextInput source="spec_5" label="Termék Jellemzők 5" />

            </FormTab>
            <FormTab label="Jogi és Igénylési feltételek">
                <RichTextInput label="Jogi Feltételek" source="description" toolbar={[
                  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                  ['blockquote', 'code-block'],

                  ['link', 'image'],
                  [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                  [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                  [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                  [{ 'direction': 'rtl' }],                         // text direction

                  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                  [{ 'font': [] }],
                  [{ 'align': [] }],

                  ['clean']                                         // remove formatting button
                ]} validate={ required() } />
                <hr className={'jogi-feltetelek-szeparator'} />
               <RichTextInput label="Igénylési Feltételek" source="conditions" toolbar={[
                  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                  ['blockquote', 'code-block'],

                  ['link', 'image'],
                  [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                  [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                  [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                  [{ 'direction': 'rtl' }],                         // text direction

                  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                  [{ 'font': [] }],
                  [{ 'align': [] }],

                  ['clean']                                         // remove formatting button
                ]} />
        {permissions === 'admin' && <TextInput label="Kiemelt kép (URL)" source="image" /> }
            </FormTab>
            <FormTab label="Időzítés">
                <DateInput className="label-top" source="start_time" label="Időzített indulás (csak ÚJ és INAKTÍV termék esetén)" allowEmpty={true} />
                <DateInput className="label-top" source="end_time" label="Időzített leállítás (csak AKTÍV, futó termék esetén)" allowEmpty={true} />
            </FormTab>
            { permissions === 'admin' && <FormTab label="Kalkulátor">
            <ReferenceArrayInput label="Címkék" source="tags" reference="tags" perPage={100} >
                <SelectArrayInput optionText="name" />
            </ReferenceArrayInput>
            <FormDataConsumer>
            {({formData, ...rest}) => {

                const baseData = findDataFields(formData.categories);
                
                
                const renderCells = cells => cells.map(({ editComponent: Component, props }) => 
                ( props.tooltip ?
                    <div class="tooltip">
                        <span class="tooltiptext">{props.tooltip}</span>
                        <div style={styles.divCell}>
                            <Component {...props} className="label-top" />
                        </div>
                    </div>
                    :
                    <div style={styles.divCell}>
                        <Component {...props} className="label-top" />
                    </div> )
                );
                
                const renderRows = rows => rows.map(row => <div style={styles.divRow}>{renderCells(row)}</div>);
                return <div style={styles.divTable}> {renderRows(baseData)} </div>;

            }}
            </FormDataConsumer>
            <FormDataConsumer>
            {({formData, ...rest}) => {

                const baseRates = findRatesFields(formData.categories);
                const renderCells = cells => cells.map(({ editComponent: Component, props }) => <Component {...props} className="label-top" />);
                return (
                    <ArrayInput record={props.record} source="rates" label="Sávok" >
                    <SimpleFormIterator2>
                        {renderCells(baseRates)}
                    </SimpleFormIterator2>
                </ArrayInput>
                )
            }}
            </FormDataConsumer>
            </FormTab> }
        </TabbedForm>
    </Create>
    );
}
