import React from 'react';
import {
    List,
    Filter,
    Datagrid,
    ReferenceField,
    ReferenceInput,
    TextField,
    SelectInput,
    Pagination,
    NumberField,
} from 'react-admin';

const BannerClickPagination = props => <Pagination rowsPerPageOptions={[ 10, 25, 50, 100]} {...props} />

const BannerClickFilter = ( { permissions, ...props }) => (
    <Filter {...props}>
        <ReferenceInput source="user_id" reference="users" label="Hirdető" perPage={100} alwaysOn >
            <SelectInput source="name" />
        </ReferenceInput>
        <SelectInput label="Szűrő" source="interval" choices={[
            {id: "week", name: "Utolsó 1 hét" },
            {id: "lastmonth", name: "Adott hónap" },
            {id: "month", name: "1 hónapra visszamenőleg" },
            {id: "all", name: "Összes" },
        ]} alwaysOn />
    </Filter>
);

export const BannerClickList = props => (
    <List {...props} title="Banner Kattintások" filters={<BannerClickFilter />} filterDefaultValues={{ interval: "week"}} pagination={<BannerClickPagination />} bulkActions={false} exporter={false} >
        <Datagrid rowClick="show">
            <ReferenceField label="Hirdető" source="user_id" reference="users" linkType={false}>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Banner" source="banner_id" reference="banner" linkType={false}>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Oldal" source="site_id" reference="sites" linkType={false}>
                <TextField source="name" />
            </ReferenceField>
            <TextField source="created" label="Létrehozva" />
            <NumberField source="ip" label="IP" />
        </Datagrid>
    </List>
);
