import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Done from '@material-ui/icons/Done';
import { Button, startUndoable, crudUpdateMany } from 'react-admin';

class BulkActiveButton extends Component {
    handleClick = () => {
        const { basePath, startUndoable, resource, selectedIds } = this.props;
        console.log("selectedIds: ", selectedIds);
        startUndoable(
            crudUpdateMany(
                resource,
                selectedIds,
                { status: 1 },
                basePath
            )
        );
    };

    render() {
        return (
            <Button
            label="Aktivál"
            onClick={this.handleClick}
            >
            <Done />
            </Button>
        );
    }
}

BulkActiveButton.propTypes = {
    resource: PropTypes.string.isRequired,
    selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    startUndoable: PropTypes.func.isRequired,
};

export default connect(
    undefined,
    { startUndoable }
)(BulkActiveButton);
