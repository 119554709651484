import debounce from 'lodash/debounce';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Quill from 'quill';
import { addField } from 'ra-core';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import { htmlEditButton } from "./quill.htmlEditButton.js";

import styles from './styles';

export class RichTextInput extends Component {
    lastValueChange = null;

    static propTypes = {
        addLabel: PropTypes.bool.isRequired,
        classes: PropTypes.object,
        input: PropTypes.object,
        label: PropTypes.string,
        meta: PropTypes.object,
        options: PropTypes.object,
        source: PropTypes.string,
        toolbar: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.bool,
            PropTypes.shape({
                container: PropTypes.array,
                handlers: PropTypes.object,
            }),
        ]),
        fullWidth: PropTypes.bool,
    };

    static defaultProps = {
        addLabel: true,
        options: {}, // Quill editor options
        record: {},
        toolbar: true,
        fullWidth: true,
    };

    componentDidMount() {
        const {
            input: { value },
//            toolbar,
            options,
        } = this.props;

        Quill.register("modules/htmlEditButton", htmlEditButton);
        var toolbarOptions = [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],

            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction

            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],

            ['clean'],                                         // remove formatting button
            ['image']
        ];

        this.quill = new Quill(this.divRef, {
            // modules: { toolbar, clipboard: { matchVisual: false } },
            modules: {
                clipboard: { matchVisual: false },
                htmlEditButton: {},
                toolbar: {
                    container: toolbarOptions,
                    handlers: {
                        image: () => {
                            var range = this.quill.getSelection();
                            var value = prompt('What is the image URL');
                            if(value){
                                this.quill.insertEmbed(range.index, 'image', value, Quill.sources.USER);
                            }
                        }
                    }
                }
            },
            theme: 'snow',
            ...options,
        });

        this.quill.setContents(this.quill.clipboard.convert(value));

        this.editor = this.divRef.querySelector('.ql-editor');
        this.quill.on('text-change', this.onTextChange);
    }

    componentDidUpdate() {
        if (this.lastValueChange !== this.props.input.value) {
            const selection = this.quill.getSelection();
            this.quill.setContents(
                this.quill.clipboard.convert(this.props.input.value)
            );
            if (selection && this.quill.hasFocus()) {
                this.quill.setSelection(selection);
            }
        }
    }

    componentWillUnmount() {
        this.quill.off('text-change', this.onTextChange);
        this.onTextChange.cancel();
        this.quill = null;
    }

    onTextChange = debounce(() => {
        const value =
            this.editor.innerHTML === '<p><br></p>'
                ? ''
                : this.editor.innerHTML;
        this.lastValueChange = value;
        this.props.input.onChange(value);
    }, 500);

    updateDivRef = ref => {
        this.divRef = ref;
    };

    render() {
        const { touched, error, helperText = false } = this.props.meta;
        return (
            <FormControl
                error={!!(touched && error)}
                fullWidth={this.props.fullWidth}
                className="ra-rich-text-input"
            >
                <div data-testid="quill" ref={this.updateDivRef} />
                {touched && error && (
                    <FormHelperText error className="ra-rich-text-input-error">
                        {error}
                    </FormHelperText>
                )}
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        );
    }
}

const RichTextInputWithField = addField(withStyles(styles)(RichTextInput));

RichTextInputWithField.defaultProps = {
    addLabel: true,
    fullWidth: true,
};
export default RichTextInputWithField;
