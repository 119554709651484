import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import GetApp from '@material-ui/icons/GetApp'
import { API_URL } from '../config';

class DownloadButton extends Component {
    handleClick = () => {
        const { record } = this.props;
        window.location.href=`${API_URL}/filemanager/${record.id}`;
    }

    render() {
        return <Button label="Download" onClick={this.handleClick}> <GetApp />Download</Button>;
    }
}

DownloadButton.propTypes = {
        record: PropTypes.object,
};

export default connect(null, { })(DownloadButton);
