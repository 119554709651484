import React from 'react';

import { BooleanInput, BooleanField } from 'react-admin';

export const ZeroOneBooleanField = ({ record ={}, source, label="" }) => {
        const newRecord = {...record}
        newRecord[source] = record[source] ? true : false;

        return <BooleanField record={newRecord} source={source} label={label} />
};

export const ZeroOneBooleanInput = ({ record ={}, source, label="", className="" }) => {
    const newRecord = {...record}
    newRecord[source] = record[source] ? true : false;

    return <BooleanInput record={newRecord} source={source} label={label} className={className} />
};
