import React from 'react';
import {
    Show,
    TabbedShowLayout,
    TextField,
    NumberField,
    Tab,
    RichTextField,
    DateField,
    ArrayField,
    UrlField,
    ReferenceArrayField,
    Datagrid,
    SelectField,
    SingleFieldList,
    ChipField,
    Labeled,
} from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import { ZeroOneBooleanField } from '../field/bool';
import { SiteID2Site } from '../field/Site';
import { styles, findLabels, findDataFields, findRatesFields } from '../constants';
import AdsEditButton from '../button/AdsEditButton';
import SiteadsBanner from './Banner.js';

const cardActionStyle = {
    justifyContent: 'flex-end',
};

const AdTitle = ({ record }) => {
        return <span>{record ? `${record.title}` : ''}</span>;
};

const AdShowActions = ({ basePath, data, resource }) => {
    var loc = window.location.href.split("/");
    var tabID = loc[7] || '';
    console.log("tabID: ", tabID);
    return (
    <CardActions style={cardActionStyle}>
            <AdsEditButton basePath={basePath} record={data} tabID={tabID} />
    </CardActions>
)};

const DataShow = ({ record }) => {
    const dataLabels = findLabels(record.categories);

    var label3 = dataLabels.label3 || "";
    var kieg = "Kiegészítő adat";
    var kieg3 = dataLabels.label3 ? "Kiegészítő adat" : "";

    return (
        <>
            <div style={styles.divTable}>
                <div style={styles.divRow}>
                    <div style={styles.divCell}>
                        <Labeled label={dataLabels.label1}><TextField record={record} source="data_1" label={dataLabels.label1} /></Labeled>
                    </div>
                    <div style={styles.divCell}>
                        <Labeled label={kieg}><TextField record={record} source="data_2" label={kieg} /></Labeled>
                    </div>
                </div>
                <div style={styles.divRow}>
                    <div style={styles.divCell}>
                        <Labeled label={dataLabels.label2}><TextField record={record} source="data_3" label={dataLabels.label2} /></Labeled>
                    </div>
                    <div style={styles.divCell}>
                        <Labeled label={kieg}><TextField record={record} source="data_4" label={kieg} /></Labeled>
                    </div>
                </div>
                <div style={styles.divRow}>
                    <div style={styles.divCell}>
                        { label3 && <Labeled label={label3}><TextField record={record} source="data_5" label={label3} /></Labeled> }
                    </div>
                    <div style={styles.divCell}>
                        { kieg3 && <Labeled label={kieg3}><TextField record={record} source="data_6" label={kieg3} /></Labeled> }
                    </div>
                </div>
            </div>
        </>
    );
};

const SpecShow = ({ record }) => {
    return (
        <>
            <div>{ record.spec_1 && <Labeled label="Termék Jellemzők 1"><TextField record={record} source="spec_1" label="Termék Jellemzők 1" /></Labeled> }</div>
            <div>{ record.spec_2 && <Labeled label="Termék Jellemzők 2"><TextField record={record} source="spec_2" label="Termék Jellemzők 2" /></Labeled> }</div>
            <div>{ record.spec_3 && <Labeled label="Termék Jellemzők 3"><TextField record={record} source="spec_3" label="Termék Jellemzők 3" /></Labeled> }</div>
            <div>{ record.spec_4 && <Labeled label="Termék Jellemzők 4"><TextField record={record} source="spec_4" label="Termék Jellemzők 4" /></Labeled> }</div>
            <div>{ record.spec_5 && <Labeled label="Termék Jellemzők 5"><TextField record={record} source="spec_5" label="Termék Jellemzők 5" /></Labeled> }</div>
        </>
    );
};

const CalculatorShow1 = ({ record }) => {
    const baseData = findDataFields(record.categories);
    
    
    const renderCells = cells => cells.map(({ showComponent: Component, props }) => 
            ( props.tooltip ?
            <div class="tooltip">
                <span class="tooltiptext">{props.tooltip}</span>
                    <div style={styles.divCell}>
                        <Labeled label={props.label}>
                            <Component record={record} {...props} className="label-top" />
                        </Labeled>
                    </div>
            </div> 
            :
            <div style={styles.divCell}>
                <Labeled label={props.label}>
                    <Component record={record} {...props} className="label-top" />
                </Labeled>
            </div>
        )
            );
    
    
    const renderRows = rows => rows.map(row => <div style={styles.divRow}>{renderCells(row)}</div>);
    return <div style={styles.divTable}> {renderRows(baseData)} </div>;
};

const CalculatorShow2 = ({ record }) => {
    const baseRates = findRatesFields(record.categories);
    const renderCells = cells => cells.map(({ showComponent: Component, props }) => <Component {...props} className="label-top" />);
    return (
        <Labeled label="Sávok">
        <ArrayField record={record} source="rates" >
            <Datagrid>
                {renderCells(baseRates)}
            </Datagrid>
        </ArrayField>
        </Labeled>
    );
};

const ShowTabForm = ({ permissions, record }) => {
    const myClickType = record.click_type;

    return (
        <ArrayField source="siteads" addLabel={false} record={record} >
            <Datagrid>
                <ZeroOneBooleanField source="status" label="Aktiv?" record={record} />
        { permissions === 'admin' && <ZeroOneBooleanField source="is_primary" label="Elsődleges?" record={record} /> }
                <ZeroOneBooleanField source="is_promo" label="Promóció?" record={record} />
                <SiteID2Site />
                { myClickType === 0 && <UrlField label="Hivatkozás" source="href" /> }
                { myClickType === 0 && <UrlField label="Visszahívás URL" source="href_call" /> }
	    { permissions === 'admin' && <UrlField label="Extra HTML" source="extra_html" /> }
	    { permissions === 'admin' && <UrlField label="Ismerd meg! URL" source="href_list" /> }
            </Datagrid>
        </ArrayField>
    );

};

export const AdShow = ({permissions, ...props}) => (
    <Show {...props} title={<AdTitle />} actions={<AdShowActions />}>
        <TabbedShowLayout>
                <Tab label="Alapadatok" >
                    <SiteadsBanner />
                    <TextField label="Azonosító" source="id" />
                    <TextField label="Hirdető" source="users.name" />
                    <TextField label="Termék Neve" source="title" />
    {permissions === 'admin' && <TextField label="Beküldési név, amennyiben külünbözik a termék nevétől (pl. Sberbank)" source="crm_name" className="label-top" /> }
                    <SelectField label="Státusz" source="status" choices={[
                        {status: 0, name: "elfogadásra vár" },
                        {status: 1, name: "aktív" },
                        {status: 2, name: "inaktív" },
                        {status: 3, name: "archív" },
                        {status: 4, name: "időzített" },
                    ]} optionText="name" optionValue="status" />
                    <SelectField label="Hirdetés típusa" source="click_type" choices={[
                        { id: 0, name: "CT Hirdetés"},
                        { id: 1, name: "Lead Hirdetés"},
                    ]} optionText="name" optionValue="id" />
                    <SelectField label="Minősített fogyasztóbarát hitel?" source="logo_display" choices={[
                        { id: 0, name: "Nem"},
                        { id: 1, name: "Igen"},
                    ]} optionText="name" optionValue="id" />
                    <DateField label="Létrehozás dátuma" source="time" />
                    <NumberField label="Verzió" source="revision" />
                </Tab>
                <Tab label="Jellemzők">
                    <ReferenceArrayField label="Kategóriák" source="categories" reference="categories_list" linkType={false}>
                        <SingleFieldList linkType={false}>
                            <ChipField source="name" />
                        </SingleFieldList>
                    </ReferenceArrayField>
                    <TextField label="Hirdetmény URL" source="proclamation_url" />
                    <TextField label="Hirdetmény Szöveg" source="proclamation_text" />
                    <DataShow />
                    <SpecShow />
                </Tab>
                <Tab label="Jogi és Igénylési Feltételek">
                    <RichTextField source="description" label="Jogi Feltételek" />
                    <RichTextField source="conditions" label="Igénylési Feltételek" />
                    <TextField label="Kiemelt kép (URL)" source="image" />
                </Tab>
                <Tab label="Időzítés">
                    <DateField source="start_time" label="Időzített indulás (csak ÚJ és INAKTÍV termék esetén)" />
                    <DateField source="end_time" label="Időzített leállítás (csak AKTÍV, futó termék esetén)" />
                    <DateField source="effective_date" label="Termékjellemzők avagy kalkulátor adatok időzített élesítése, csak AKTÍV, futó termék esetén, az alábbi időpontban jelenjenek meg a változtatások" />
                </Tab>
                <Tab label="Oldalak">
                    <ShowTabForm permissions={permissions} />
                </Tab>
	{ permissions === 'admin' && <Tab label="Kalkulátor">
                    <ReferenceArrayField label="Címkék" source="tags" reference="tags" linkType={false}>
                        <SingleFieldList linkType={false}>
                            <ChipField source="name" />
                        </SingleFieldList>
                    </ReferenceArrayField>
                    <CalculatorShow1 />
                    <CalculatorShow2 />
                </Tab>}
            </TabbedShowLayout>
    </Show>
);
