import React from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    ReferenceField,
} from 'react-admin';

export const ProductShow = ({ permissions, ...props }) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField label="Hirdetés azonosító" source="id" />
            <ReferenceField source="user_id" reference="users" linkType={false} label="Hirdető" >
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Kategória" source="category_id" reference="categories_list" linkType={false} >
                    <TextField source="name" />
            </ReferenceField>
            <TextField label="Termék Neve" source="title" />
        </SimpleShowLayout>
    </Show>
);