import React from 'react';
import { AppBar, UserMenu, MenuItemLink } from 'react-admin';
//import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';

const styles = {
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
};


let username = sessionStorage.getItem('username');

const CustomUserMenu = ({ ...props }) => {
    return (
        <UserMenu {...props}>
            <MenuItemLink
                to="/preferences"
                primaryText="Beállítások"
                leftIcon={<SettingsIcon />}
            />
            <MenuItemLink
                to="/profile"
                primaryText="Profil"
                leftIcon={<SettingsIcon />}
            />
        </UserMenu>
    );
};

const CustomAppBar = withStyles(styles)(({ classes, ...props }) => (
     <AppBar {...props} userMenu={<CustomUserMenu />} >
        <Typography
            variant="title"
            color="inherit"
            className={classes.title}
            id="react-admin-title"
        />
        Bejelentkezett felhasználó: {username}
        <span className={classes.spacer} />
    </AppBar>
));

export default CustomAppBar;
