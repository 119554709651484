import { CatMapList, CatMapCreate, CatMapEdit, CatMapShow } from './catmap';
import CatMapIcon from '@material-ui/icons/FeaturedPlayList';

export default {
    list: CatMapList,
    edit: CatMapEdit,
    show: CatMapShow,
    create: CatMapCreate,
    icon: CatMapIcon,
};
