import React from 'react';
import {
    List,
    Edit,
    Create,
    Datagrid,
    TextField,
    TextInput,
    SimpleForm,
    Pagination,
    EditButton,
} from 'react-admin';

const TagsPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />

export const TagsCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list" submitOnEnter={false}>
            <TextInput source="name" label="Név" />
            <TextInput source="description" label="Leírás" />
        </SimpleForm>
    </Create>
)

export const TagsEdit = props => (
    <Edit {...props} undoable={false} >
        <SimpleForm redirect="show" submitOnEnter={false} >
            <TextInput source="name" label="Név" />
            <TextInput source="description" label="Leírás" />
        </SimpleForm>
    </Edit>
)

export const TagsList = props => (
    <List {...props} bulkActions={false} exporter={false} pagination={<TagsPagination />} title="Kategóriák" >
        <Datagrid>
            <TextField label="ID" source="id" />
            <TextField label="Név" source="name" />
            <TextField label="Leírás" source="description" />
            <EditButton />
        </Datagrid>
    </List>
);