import {
    FMNyomtatvanyList,
    FMNyomtatvanyCreate,
    FMNyomtatvanyEdit,
} from './nyomtatvanyok';
import FMNyomtatvanyIcon from '@material-ui/icons/Label';

export default {
    create: FMNyomtatvanyCreate,
    edit: FMNyomtatvanyEdit,
    list: FMNyomtatvanyList,
    icon: FMNyomtatvanyIcon,
};
