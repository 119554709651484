import React from 'react';
import {
    Edit,
    SimpleForm,
} from 'react-admin';
import { BannerFields } from './BannerInputs';

export const BannerEdit = props => (
    <Edit {...props} undoable={false} >
        <SimpleForm redirect="show" submitOnEnter={false} >
            <BannerFields />
        </SimpleForm>
    </Edit>
)