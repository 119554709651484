import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

import {
          XAxis, YAxis, CartesianGrid, Tooltip, Label, LabelList, BarChart, Bar, Legend,
} from 'recharts';


export const LeadStats = ({ daily, monthly, lead }) => {
    return (
                                <ExpansionPanel>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography align="center" variant="title" color="textPrimary">{lead} leadek</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <BarChart width={700} height={400} data={daily} margin={{ top: 40, right: 30, left: 20, bottom: 50, }} >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="day">
                                                <Label value="Napi statisztika" offset={30} position="bottom" />
                                            </XAxis>
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Bar dataKey="expressz" stackId="a" fill="#98cb01" />
                                            <Bar dataKey="akolcson" stackId="a" fill="#fc763e" />
                                            <Bar dataKey="banknavigator" stackId="a" fill="#49bbe4" >
                                                <LabelList datakey="total" position="top" />
                                            </Bar>
                                        </BarChart>
                                        <BarChart width={600} height={400} data={monthly} margin={{ top: 40, right: 30, left: 20, bottom: 50, }} >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="month">
                                                <Label value="Havi statisztika" offset={30} position="bottom" />
                                            </XAxis>
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Bar dataKey="expressz" stackId="a" fill="#98cb01" />
                                            <Bar dataKey="akolcson" stackId="a" fill="#fc763e" />
                                            <Bar dataKey="banknavigator" stackId="a" fill="#49bbe4" >
                                                <LabelList datakey="total" position="top" />
                                            </Bar>
                                        </BarChart>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
    );

};
