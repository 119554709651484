import React, { Component } from 'react';
import { GET_LIST } from 'react-admin';
import dataProviderFactory from '../dataprovider';

import { push } from 'react-router-redux';
import { connect } from 'react-redux';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { LeadStats} from './LeadStats';

const styles2 = {
        flex: { display: 'flex' },
        flexColumn: { display: 'flex', flexDirection: 'column' },
        leftCol: { flex: 1, marginRight: '1em' },
        rightCol: { flex: 1, marginLeft: '1em' },
        singleCol: { marginTop: '2em', marginBottom: '2em' },
        main: { display: "flex", marginRight: '1em', marginTop: 20 },
};

class Dashboard extends Component {

    state = {};

    componentDidMount() {
        const { push } = this.props;

        dataProviderFactory('simplerest').then(
             dataProvider => {
                 ( this.props.permissions === 'admin' || this.props.permissions === 'media' ) &&
                 dataProvider(GET_LIST, 'crmstats_sberbank', {
                    sort: { field: '', order: '' },
                    pagination: { page: 1, perPage: 0 },
                })
                    .then(response => { this.setState({ crmstats_sberbank_daily: response.data[0] }); this.setState({ crmstats_sberbank_monthly: response.data[1] })})
                    .catch((e) => {
                        push('/login');
                    });

                ( this.props.permissions === 'admin' || this.props.permissions === 'media' ) &&
                 dataProvider(GET_LIST, 'crmstats_mkb', {
                    sort: { field: '', order: '' },
                    pagination: { page: 1, perPage: 0 },
                })
                    .then(response => { this.setState({ crmstats_mkb_daily: response.data[0] }); this.setState({ crmstats_mkb_monthly: response.data[1] })})
                    .catch((e) => {
                        push('/login');
                    });

               ( this.props.permissions === 'admin' || this.props.permissions === 'media' ) &&
                 dataProvider(GET_LIST, 'crmstats_kh', {
                    sort: { field: '', order: '' },
                    pagination: { page: 1, perPage: 0 },
                })
                    .then(response => { this.setState({ crmstats_kh_daily: response.data[0] }); this.setState({ crmstats_kh_monthly: response.data[1] })})
                    .catch((e) => {
                        push('/login');
                    });

              ( this.props.permissions === 'admin' || this.props.permissions === 'media' ) &&
                 dataProvider(GET_LIST, 'crmstats_unicredit', {
                    sort: { field: '', order: '' },
                    pagination: { page: 1, perPage: 0 },
                })
                    .then(response => { this.setState({ crmstats_unicredit_daily: response.data[0] }); this.setState({ crmstats_unicredit_monthly: response.data[1] })})
                    .catch((e) => {
                        push('/login');
                    });

              ( this.props.permissions === 'admin' || this.props.permissions === 'media' ) &&
                 dataProvider(GET_LIST, 'crmstats_erste', {
                    sort: { field: '', order: '' },
                    pagination: { page: 1, perPage: 0 },
                })
                    .then(response => { this.setState({ crmstats_erste_daily: response.data[0] }); this.setState({ crmstats_erste_monthly: response.data[1] })})
                    .catch((e) => {
                        push('/login');
                    });

           ( this.props.permissions === 'admin' || this.props.permissions === 'media' ) &&
                 dataProvider(GET_LIST, 'crmstats_takarekbank', {
                    sort: { field: '', order: '' },
                    pagination: { page: 1, perPage: 0 },
                })
                    .then(response => { this.setState({ crmstats_takarekbank_daily: response.data[0] }); this.setState({ crmstats_takarekbank_monthly: response.data[1] })})
                    .catch((e) => {
                        push('/login');
                    });

           ( this.props.permissions === 'admin' || this.props.permissions === 'media' ) &&
                 dataProvider(GET_LIST, 'crmstats_minikolcson', {
                    sort: { field: '', order: '' },
                    pagination: { page: 1, perPage: 0 },
                })
                    .then(response => { this.setState({ crmstats_minikolcson_daily: response.data[0] }); this.setState({ crmstats_minikolcson_monthly: response.data[1] })})
                    .catch((e) => {
                        push('/login');
                    });

           ( this.props.permissions === 'admin' || this.props.permissions === 'media' ) &&
                dataProvider(GET_LIST, 'crmstats_cityleasing', {
                    sort: { field: '', order: '' },
                    pagination: { page: 1, perPage: 0 },
                })
                    .then(response => { this.setState({ crmstats_cityleasing_daily: response.data[0] }); this.setState({ crmstats_cityleasing_monthly: response.data[1] })})
                    .catch((e) => {
                        push('/login');
                    });

            ( this.props.permissions === 'admin' || this.props.permissions === 'media' ) &&
                dataProvider(GET_LIST, 'crmstats_mkb_vallalkozoi', {
                    sort: { field: '', order: '' },
                    pagination: { page: 1, perPage: 0 },
                })
                    .then(response => { this.setState({ crmstats_mkb_vallalkozoi_daily: response.data[0] }); this.setState({ crmstats_mkb_vallalkozoi_monthly: response.data[1] })})
                    .catch((e) => {
                        push('/login');
                    });

           ( this.props.permissions === 'admin' || this.props.permissions === 'media' ) &&
                dataProvider(GET_LIST, 'leadstats', {
                    sort: { field: '', order: '' },
                    pagination: { page: 1, perPage: 0 },
                })
                    .then(response => { this.setState({ leadstats_daily: response.data[0] }); this.setState({ leadstats_monthly: response.data[1] })})
                    .catch((e) => {
                        push('/login');
                    });

            }
        );
    }

    render() {
        const {
            crmstats_sberbank_daily,
            crmstats_sberbank_monthly,
            crmstats_mkb_daily,
            crmstats_mkb_monthly,
            crmstats_kh_daily,
            crmstats_kh_monthly,
            crmstats_erste_daily,
            crmstats_unicredit_daily,
            crmstats_takarekbank_daily,
            crmstats_minikolcson_daily,
            crmstats_cityleasing_daily,
            crmstats_mkb_vallalkozoi_daily,
            crmstats_erste_monthly,
            crmstats_unicredit_monthly,
            crmstats_takarekbank_monthly,
            crmstats_minikolcson_monthly,
            crmstats_mkb_vallalkozoi_monthly,
            crmstats_cityleasing_monthly,
            leadstats_daily,
            leadstats_monthly,
        } = this.state;

        return (
            <>
            <div style={styles2.flex}>
                <div style={styles2.leftCol}>
                    <div style={{width: '96%', marginTop: 20}}>
                        { this.props.permissions === 'admin' || this.props.permissions === 'hirdeto' ?
                        <Card>
                            <CardHeader title="Gyorslinkek" style={{ textAlign: 'center' }}/>
                        </Card> : null}
                     </div>
                     <div style={{width: '96%', marginTop: 20}}>
                            { this.props.permissions === 'admin' || this.props.permissions === 'hirdeto' ?
                            <ExpansionPanel>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography align="center" variant="title" color="textPrimary">Hirdetések Kezelése</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Button href="/#/ads/create">Új hirdetés</Button>
                                    <Button href="/#/ads">Hirdetések listázása</Button>
                                    { this.props.permissions === 'admin' && <Button href="/#/ads-featured">Promóció kezelése</Button>}
                                </ExpansionPanelDetails>
                            </ExpansionPanel> : null }
                            { this.props.permissions === 'admin' && <ExpansionPanel>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography align="center" variant="title" color="textPrimary">Felhasználók Kezelése</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Button href="/#/users/create">Új Felhasználó</Button>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>}
                            { this.props.permissions === 'admin' && <ExpansionPanel>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography align="center" variant="title" color="textPrimary">Lead Listák</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Button href="/#/leads">Finex</Button>
                                    <Button href="/#/crm_sberbank">Sberbank</Button>
                                    <Button href="/#/crm_mkb">MKB</Button>
                                    <Button href="/#/crm_kh">KH</Button>
                                    <Button href="/#/crm_erste">ERSTE</Button>
                                    <Button href="/#/crm_unicredit">Unicredit</Button>
                                    <Button href="/#/crm_takarekbank">Takarékbank</Button>
                                    <Button href="/#/crm_minikolcson">Minikölcsön</Button>
                                    <Button href="/#/crm_mkb_vallalkozoi">MKB Vállalkozói</Button>
                                    <Button href="/#/crm_cityleasing">City Leasing</Button>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>}
                    </div>
                    { this.props.permissions === 'admin' || this.props.permissions === 'media' ? <div style={{width: '96%', marginTop: 20}}>
                        <Card>
                            <CardHeader title="Lead Statisztikák" style={{ textAlign: 'center' }}/>
                        </Card>
                    </div> : null }
                    { this.props.permissions === 'admin' || this.props.permissions === 'media' ? <div style={{width: '96%', marginTop: 20}}>
                        <LeadStats daily={leadstats_daily} monthly={leadstats_monthly} lead="Belso CRM"/>
                        <LeadStats daily={crmstats_sberbank_daily} monthly={crmstats_sberbank_monthly} lead="Sberbank" />
                        <LeadStats daily={crmstats_mkb_daily} monthly={crmstats_mkb_monthly} lead="MKB" />
                        <LeadStats daily={crmstats_kh_daily} monthly={crmstats_kh_monthly} lead="K&H" />
                        <LeadStats daily={crmstats_erste_daily} monthly={crmstats_erste_monthly} lead="ERSTE" />
                        <LeadStats daily={crmstats_unicredit_daily} monthly={crmstats_unicredit_monthly} lead="Unicredit" />
                        <LeadStats daily={crmstats_takarekbank_daily} monthly={crmstats_takarekbank_monthly} lead="Takarékbank" />
                        <LeadStats daily={crmstats_minikolcson_daily} monthly={crmstats_minikolcson_monthly} lead="Minikölcsön" />
                        <LeadStats daily={crmstats_mkb_vallalkozoi_daily} monthly={crmstats_mkb_vallalkozoi_monthly} lead="MKB Vállalkozói" />
                        <LeadStats daily={crmstats_cityleasing_daily} monthly={crmstats_cityleasing_monthly} lead="City Leasing" />
                    </div> : null }
                </div>
            </div>
            </>
        );
    }
}
export default connect(null, { push })(Dashboard);
