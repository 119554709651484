import React from 'react';
import {
    ReferenceInput,
    ReferenceArrayInput,
    TextInput,
    SelectInput,
    SelectArrayInput,
    DateInput,
    LongTextInput,
    required,
    ImageInput,
    ImageField,
    FormDataConsumer,
} from 'react-admin';
import { MyStatus } from '../field/customAdsFields';
import Typography from '@material-ui/core/Typography';
import { ZeroOneBooleanInput } from '../field/bool';


export const BannerFields = ({ record, basePath }) => {
    return (
        <>
            <div class="table">
                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">Hirdető:</Typography>
                    </div>
                    <div class="cellvalue">
                        <ReferenceInput record={record} basePath={basePath} label="" source="user_id" reference="users" perPage={100} >
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">Hirdetés típusa:</Typography>
                    </div>
                    <div class="cellvalue">
                        <SelectInput record={record} basePath={basePath} label="" source="banner_type" choices={[
                            { id: 1, name: 'Normál' },
                            { id: 2, name: 'Mini' },
                        ]} />
                    </div>
                </div>
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        if (formData === undefined) { formData = {} }
                        if (formData.banner_type === undefined) {
                            formData.banner_type = 1;
                        }
                        return (
                            <>
                                { formData.banner_type === 2 ? (
                                    <>
                                        <div class="row">
                                            <div class="cellkey add-padding">
                                                <Typography color="inherit">Mini banner színe (6 karakterű színkód):</Typography>
                                            </div>
                                            <div class="cellvalue">
                                                <TextInput record={record} basePath={basePath} label="" source="color" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="cellkey add-padding">
                                                <Typography color="inherit">Mini banner szöveg alul:</Typography>
                                            </div>
                                            <div class="cellvalue">
                                                <TextInput record={record} basePath={basePath} label="" source="title" />
                                            </div>
                                        </div>
                                    </>
                                ) : null
                            }
                            </> 
                            );
                        }
                    }
                </FormDataConsumer>
                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">Elnevezés:</Typography>
                    </div>
                    <div class="cellvalue">
                        <TextInput record={record} basePath={basePath} label="" source="name" validate={ required() }/>
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">Státusz:</Typography>
                    </div>
                    <div class="cellvalue">
                    <MyStatus record={record} basePath={basePath} banner={true} />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">HTML Banner esetén kikapcsolandó!</Typography>
                    </div>
                    <div class="cellvalue">
                        <ZeroOneBooleanInput record={record} basePath={basePath} label="" source="link_wrap" />
                    </div>
                </div>
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        if (formData === undefined) { formData = {} }
                        if (formData.link_wrap === undefined) {
                            formData.link_wrap = 1;
                        }
                        return (
                            <>
                                { formData.link_wrap === 1 ? (
                                    <>
                                        <div class="row">
                                            <div class="cellkey add-padding">
                                                <Typography color="inherit">Feltöltendő bannerek (kép):</Typography>
                                            </div>
                                            <div class="cellvalue">
                                                <ImageInput record={record} basePath={basePath} source="bannerimgs" label="" accept="image/*" multiple={true} {...rest}>
                                                    <ImageField source="url" title="title" />
                                                </ImageInput>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div class="row">
                                            <div class="cellkey add-padding">
                                                <Typography color="inherit">HTML Banner:</Typography>
                                            </div>
                                            <div class="cellvalue">
                                                <LongTextInput record={record} basePath={basePath} label="" source="banner" validate={ required() } {...rest} />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="cellkey add-padding">
                                                <Typography color="inherit">HTML Mobil Banner:</Typography>
                                            </div>
                                            <div class="cellvalue">
                                                <LongTextInput record={record} basePath={basePath} label="" source="banner_mobile" validate={ required() } {...rest} />
                                            </div>
                                        </div>
                                    </>
                                    )
                                }
                            </> 
                            );
                        }
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        if (formData === undefined) { formData = {} }
                        if (formData.banner_type === undefined) {
                            formData.banner_type = 1;
                        }
                        return (
                            <>
                                { formData.banner_type === 1 ? (
                                    <>
                                        <div class="row">
                                            <div class="cellkey add-padding">
                                                <Typography color="inherit">Kategóriák (ha üres, mindenhol megjelenik):</Typography>
                                            </div>
                                            <div class="cellvalue">
                                                <ReferenceArrayInput record={record} basePath={basePath} label="" source="type" reference="categories" filter={{ parent_id: null }} perPage={100} >
                                                    <SelectArrayInput optionText="name" />
                                                </ReferenceArrayInput>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="cellkey add-padding">
                                                <Typography color="inherit">Kategória kizárása (ezekben nem fog megjelenni):</Typography>
                                            </div>
                                            <div class="cellvalue">
                                                <ReferenceArrayInput record={record} basePath={basePath} label="" source="not_in_type" reference="categories" filter={{ parent_id: null }} perPage={100} >
                                                    <SelectArrayInput optionText="name" />
                                                </ReferenceArrayInput>
                                            </div>
                                        </div>
                                    </>
                                ) : null
                            }
                            </> 
                            );
                        }
                    }
                </FormDataConsumer>
                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">Hirdetés helye:</Typography>
                    </div>
                    <div class="cellvalue">
                        <ReferenceArrayInput record={record} basePath={basePath} label="" source="zones" reference="banner/zones" perPage={100} >
                            <SelectArrayInput optionText="name" />
                        </ReferenceArrayInput>
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">Link (expressz):</Typography>
                    </div>
                    <div class="cellvalue">
                        <LongTextInput record={record} basePath={basePath} label="" source="href_1" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">Link (akolcson):</Typography>
                    </div>
                    <div class="cellvalue">
                        <LongTextInput record={record} basePath={basePath} label="" source="href_2" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">Link (banknavigator):</Typography>
                    </div>
                    <div class="cellvalue">
                        <LongTextInput record={record} basePath={basePath} label="" source="href_4" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">Menü Link (expressz):</Typography>
                    </div>
                    <div class="cellvalue">
                        <LongTextInput record={record} basePath={basePath} label="" source="href_menu_1" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">Menü Link (akolcson):</Typography>
                    </div>
                    <div class="cellvalue">
                        <LongTextInput record={record} basePath={basePath} label="" source="href_menu_2" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">Menü Link (banknavigator):</Typography>
                    </div>
                    <div class="cellvalue">
                        <LongTextInput record={record} basePath={basePath} label="" source="href_menu_4" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="error" align="center" variant="title">Mobil Link nem kötelező, ha ugyanaz mint a Link!</Typography>
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">Mobil Link (expressz):</Typography>
                    </div>
                    <div class="cellvalue">
                        <LongTextInput record={record} basePath={basePath} label="" source="href_mobile_1" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">Mobil Link (akolcson):</Typography>
                    </div>
                    <div class="cellvalue">
                        <LongTextInput record={record} basePath={basePath} label="" source="href_mobile_2" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">Mobile Link (banknavigator):</Typography>
                    </div>
                    <div class="cellvalue">
                        <LongTextInput record={record} basePath={basePath} label="" source="href_mobile_4" />
                    </div>
                </div>



                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">Kezdete:</Typography>
                    </div>
                    <div class="cellvalue">
                        <DateInput record={record} basePath={basePath} label="" source="start_time" />
                    </div>
                </div>
                <div class="row">
                    <div class="cellkey add-padding">
                        <Typography color="inherit">Lejárata:</Typography>
                    </div>
                    <div class="cellvalue">
                        <DateInput record={record} basePath={basePath} label="" source="end_time" />
                    </div>
                </div>
            </div>
        </>
    );
}