import React, { Fragment } from 'react';
import {
    List,
    Show,
    Datagrid,
    TextField,
    EmailField,
    ReferenceField,
    ArrayField,
    Pagination,
    SimpleShowLayout,
    NumberField,
    Labeled,
    Filter,
    TextInput,
    CardActions,
} from 'react-admin';

import FullNameField from '../../field/FullNameField';
import { ZeroOneBooleanField } from '../../field/bool';
import BulkCRMResyncButton from '../../button/BulkCRMResyncButton';
import BulkDeleteWithConfirmButton from '../../button/BulkDeleteWithConfirmButton';
import ResendButton from '../../button/ResendButton';
import { SiteName } from '../../field/Site';
import { styles } from '../../constants';
import LastRun from '../../field/LastRun';

const CRMPagination = props => <Pagination rowsPerPageOptions={[ 10, 25, 50, 100]} {...props} />

const CRMActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
    <CardActions>
    {bulkActions && React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
    })}
    {filters && React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
    }) }
    <LastRun resource="crmlastrun_takarekbank" />
    </CardActions>
);

const CRMBulkActionButtons = ( props ) => (
    <Fragment>
        <BulkCRMResyncButton {...props} />
        <BulkDeleteWithConfirmButton {...props} />
    </Fragment>
);

const CRMFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Telefonszám keresése" source="phone" alwaysOn/>
        <TextInput label="Vezetéknév keresése" source="lastname" alwaysOn/>
        <TextInput label="Keresztnév keresése" source="firstname" alwaysOn/>
    </Filter>
);

const ResendCustomer = ({ record }) => {
    var mySent = record.is_sent;

    return (
        <>
        { mySent === 0 && <ResendButton record={record} resource="crm_takarekbank" target="customer" text="Újraküldés Ügyfélnek" /> }
        </>
    );
};

const ExpandCRMShowDetails = ( props ) => {
    return (
        <>
                    <div style={styles.divTable}>
                        <div style={styles.divRow}>
                            <div style={styles.divCell}>
                                <Labeled label="Azonosító"><TextField label="Azonosító" source="id" record={props.record} /></Labeled>
                            </div>
                            <div style={styles.divCell}>
                                <Labeled label="CRM Azonosító"><TextField label="CRM Azonosító" source="remote_id" record={props.record} /></Labeled>
                            </div>
                            <div style={styles.divCell}>
                                <Labeled label="CRM Modul Azonosító"><TextField label="CRM Modul Azonosító" source="module_id" record={props.record} /></Labeled>
                            </div>
                        </div>
                        <div style={styles.divRow}>
                            <div style={styles.divCell}>
                                <Labeled label="Név"><FullNameField label="Név" record={props.record} /></Labeled>
                            </div>
                            <div style={styles.divCell}>
                                <Labeled label="Email"><EmailField source="email" record={props.record} /></Labeled>
                            </div>
                        </div>
                        <div style={styles.divRow}>
                            <div style={styles.divCell}>
                                <Labeled label="Igényelt Hitelösszeg"><NumberField source="misc.hitelosszeg" record={props.record} /></Labeled>
                            </div>
                            <div style={styles.divCell}>
                                <Labeled label="Jövedelem"><NumberField source="misc.jovedelem" record={props.record} /></Labeled>
                            </div>
                        </div>
                        <div style={styles.divRow}>
                            <div style={styles.divCell}>
                                <Labeled label="Telefonszám"><NumberField label="Telefonszám" source="phone" record={props.record} /></Labeled>
                            </div>
                        </div>
                        <div style={styles.divRow}>
                            <div style={styles.divCell}>
                                <Labeled label="Választott Fiók Neve"><TextField source="misc.fiok_neve" label="Választott Fiók Neve" record={props.record} /></Labeled>
                            </div>
                        </div>
                        <div style={styles.divRow}>
                            <div style={styles.divCell}>
                                <Labeled label="Ügyfélnek kiküldve"><TextField source="sent" label="Ügyfélnek kiküldve" record={props.record} /></Labeled>
                            </div>
                            <div style={styles.divCell}>
                                <Labeled label="Bankba beküldve"><TextField source="submitted" label="Bankba beküldve" record={props.record} /></Labeled>
                            </div>
                            <div style={styles.divCell}>
                                <Labeled label="Létrehozás dátuma"><TextField source="created" label="Létrehozás dátuma" record={props.record} /></Labeled>
                            </div>
                        </div>
                    </div>
        </>
    );

};

const ExpandCRMShow = ( props ) => {
    return (
    <Show {...props}>
        <SimpleShowLayout>
            <ExpandCRMShowDetails />
            <ArrayField label="Összes kiküldött email" source="mails" record={props.record}>
                <Datagrid>
                    <TextField source="sent" label="Időpont" />
                    <TextField source="sent_to" label="Címzett" />
                </Datagrid>
            </ArrayField>
            <ReferenceField label="Hirdetés" source="ad_id" reference="ads">
                <TextField source="title" />
            </ReferenceField>
        </SimpleShowLayout>
    </Show>
    );
};

export const TakarekbankList = props => (
    <List {...props} actions={<CRMActions />} title="Takarékbank Leadek" filters={<CRMFilter />} pagination={<CRMPagination />} exporter={false} bulkActionButtons={<CRMBulkActionButtons />}>
        <Datagrid expand={<ExpandCRMShow />}>
            <TextField label="ID" source="id" />
            <TextField label="CRMID" source="remote_id" />
            <TextField label="Időpont" source="created" />
            <SiteName label="Oldal"/>
            <FullNameField label="Név" />
            <EmailField label="Email" source="email" />
            <ReferenceField label="Hirdetés" source="ad_id" reference="ads">
                <TextField source="title" />
            </ReferenceField>
            <TextField label="Telefonszám" source="phone" />
            <ZeroOneBooleanField source="is_sent" label="Ügyfélnek kiküldve" />
            <ZeroOneBooleanField source="is_submitted" label="Bankba beküldve" />
            <ResendCustomer />
        </Datagrid>
    </List>
);
