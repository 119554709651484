import React from 'react';
import {
    List,
    Filter,
    Datagrid,
    ReferenceField,
    ReferenceInput,
    TextField,
    SelectInput,
    SelectField,
    EditButton,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    ImageField,
} from 'react-admin';
import StatusTextField from '../field/StatusTextField';
import Typography from '@material-ui/core/Typography';

const BannerStatus = ({ record }) => {
    var statusK, color, command;
    if ( parseInt(record.status) === 0 ){
        statusK = "felfüggesztett";
        color = 'error';
    }
    else if ( parseInt(record.status) === 1 ) {
        statusK = "aktív";
        color = 'primary';
    }
    else if ( parseInt(record.status) === 2 ) {
        statusK = "lezárt";
        color = "secondary";
    }
    else if ( parseInt(record.status) === 3 ) {
        statusK = "törölt (véglegesen törölve)";
        color = "inherit";
    }
    command = <StatusTextField source="status" statusK={statusK} color={color} />;
    return (
        command
    );
};

const BannerPreview = ({ record, basePath }) => {
    if (record.banner.match(/^http/)) {
        return <ImageField record={record} basePath={basePath} source="banner" src="banner" title="File" />;
    } else {
        return <Typography color="inherit">HTML banner</Typography>;
    }
}


const BannerFilter = ( { permissions, ...props }) => (
    <Filter {...props}>
        <ReferenceInput source="user_id" reference="users" label="Hirdető" perPage={100} alwaysOn >
            <SelectInput source="name" />
        </ReferenceInput>
        <SelectInput label="Státusz" source="status" choices={[
            {id: 0, name: "felfüggesztett" },
            {id: 1, name: "aktív" },
            {id: 2, name: "lezárt" },
            {id: 3, name: "törölt (véglegesen törölve)" },
        ]} alwaysOn />
    </Filter>
);

export const BannerList = props => (
    <List {...props} title="Bannerek" filters={<BannerFilter />} filterDefaultValues={{ status: "1"}} bulkActions={false} exporter={false} >
        <Datagrid rowClick="show">
            <ReferenceField label="Hirdető" source="user_id" reference="users" linkType={false}>
                <TextField source="name" />
            </ReferenceField>
            <BannerStatus source="status" label="Státusz" />
            <TextField label="Elnevezés" source="name" />
            <SelectField source="banner_type" choices={[
                { id: 1, name: 'Normál' },
                { id: 2, name: 'Mini' },
            ]} />
            <BannerPreview />
            <TextField source="start_time" label="Kezdete" />
            <TextField source="end_time" label="Lejárata" />
            <ReferenceArrayField label="Aktív zónák" source="zones" reference="banner/zones" linkType={false}>
                <SingleFieldList linkType={false}>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
            <TextField source="created" label="Létrehozva" />
            <EditButton label="" />
        </Datagrid>
    </List>
);

//             {props.record.banner_type === 2 || props.record.banner_type === undefined && <TextField source="title" label="Mini banner szöveg alul" />}
