import React from 'react';


export const CreationBanner = (props) => (
    <span className={'siteads_banner_red'}>
        Figyelem, az összes pirossal kiemelt lépést kötelező kitölteni mentés előtt!
    </span>
);

const SiteadsBanner = ({ record }) => {
    let shouldDisplay = true;
    if ( record.siteads ) {
        record.siteads.forEach(function (e) {
        if (e.status === 1)
            shouldDisplay = false
    });}

    return shouldDisplay ? (
        <span className={'siteads_banner_red'}>
            Figyelem, ez a hirdetés egyetlen oldalon sem aktiv!
        </span>
    ) : null;
};

export default SiteadsBanner;
