import { AdList } from './AdsList';
import { AdCreate } from './AdsCreate';
import { AdEdit } from './AdsEdit';
import { AdShow } from './AdsShow';
import AdIcon from '@material-ui/icons/Book';

export default {
    list: AdList,
    edit: AdEdit,
    show: AdShow,
    create: AdCreate,
    icon: AdIcon,
};
