import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';
import Typography from '@material-ui/core/Typography';

const StatusTextField = ({ className, source, statusK, color, key, record = {}, ...rest }) => {
    return (
        <Typography
            component="span"
            body1="body1"
            className={className}
            color={color}
        >
            {statusK}
        </Typography>
    );
};

StatusTextField.propTypes = {
    addLabel: PropTypes.bool,
    basePath: PropTypes.string,
    className: PropTypes.string,
    cellClassName: PropTypes.string,
    headerClassName: PropTypes.string,
    label: PropTypes.string,
    record: PropTypes.object,
    sortBy: PropTypes.string,
    source: PropTypes.string.isRequired,
    statusK: PropTypes.string,
    color: PropTypes.string,
};

// wat? TypeScript looses the displayName if we don't set it explicitly
StatusTextField.displayName = 'StatusTextField';
const PureStatusTextField = pure(StatusTextField);

PureStatusTextField.defaultProps = {
    addLabel: true,
};

export default PureStatusTextField;
