import React from 'react';
import {
    List,
    Edit,
    Create,
    TextField,
    TextInput,
    FileInput,
    FileField,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    Filter,
    DeleteButton,
    EditButton,
    required,
} from 'react-admin';
import { Tree, NodeView, NodeActions } from 'ra-tree-ui-materialui';
import DownloadButton from '../../button/DownloadButton';

const NewsFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Oldal" source="site_id" reference="sites" allowEmpty={false} alwaysOn >
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

const NewsActions = ({ permissions, ...props }) => (
    <NodeActions {...props}>
        <DownloadButton />
        {permissions === 'admin' && <EditButton />}
        {permissions === 'admin' && <DeleteButton />}
    </NodeActions>
);


 export const FMNewsList = ({ permissions, ...props}) => (
    <List {...props} perPage={10000} filters={<NewsFilter />} filterDefaultValues={{ site_id: 1 }} title="Újságírói felület" exporter={false}>
        <Tree>
             <NodeView actions={<NewsActions permissions={permissions} />}>
                 <TextField source="name" />
             </NodeView>
        </Tree>
    </List>
);


export const FMNewsEdit = (props) => (
        <Edit {...props}>
            <SimpleForm redirect="list">
                <TextInput source="name" validate={ required() } />
            </SimpleForm>
        </Edit>
);

export const FMNewsCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <FileInput source="filecontent" label="Új cikk feltöltése" multiple={false} >
                <FileField source="name" title="title" />
            </FileInput>
            <ReferenceInput label="Oldal" source="site_id" reference="sites">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput label="Alkönyvtár" source="parent_id" reference="filemanager/subdirs_news">
                <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);
