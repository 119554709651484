import React from 'react';
import {
    List,
    Show,
    SimpleShowLayout,
    Datagrid,
    TextField,
    EditButton,
    SelectInput,
    TextInput,
    Filter,
    ReferenceField,
    ReferenceInput,
    Pagination,
    CreateButton,
    CardActions,
} from 'react-admin';

const ProductFilter = ( { permissions, ...props }) => (
    <Filter {...props}>
        <TextInput label="Termék keresése" source="title" alwaysOn />
        { permissions === 'admin' && 
        <ReferenceInput source="user_id" reference="users" label="Hirdető" perPage={100} alwaysOn >
            <SelectInput source="name" />
        </ReferenceInput> 
        }
    </Filter>
);

const ProductPagination = props => <Pagination rowsPerPageOptions={[ 10, 25, 50, 100]} {...props} />

const ProductActions = ({
    permissions,
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
    <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
                        basePath,
                        filterValues,
                        resource,
                        selectedIds,
                        onUnselectItems,
                    })}
        {filters && React.cloneElement(filters, {
                        resource,
                        showFilter,
                        displayedFilters,
                        filterValues,
                        context: 'button',
                    }) }
        <CreateButton basePath={basePath} />
    </CardActions>
);

const ExpandProductShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField label="Hirdetés azonosító" source="id" />
            <ReferenceField source="user_id" reference="users" linkType={false} label="Hirdető" >
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Kategória" source="category_id" reference="categories_list" linkType={false} >
                    <TextField source="name" />
            </ReferenceField>
            <TextField label="Termék Neve" source="title" />
        </SimpleShowLayout>
    </Show>
);

export const ProductList = ({ permissions, ...props }) => (
    <List {...props} filters={<ProductFilter permissions={permissions} />} actions={<ProductActions />} pagination={<ProductPagination />} sort={{ field: "id", order: "ASC" }} title="További Terméknevek" exporter={false} >
        <Datagrid expand={<ExpandProductShow />}>
        <TextField label="Hirdetés azonosító" source="id" />
        { permissions === 'admin' ? 
        <ReferenceField source="user_id" reference="users" linkType={false} label="Hirdető" >
            <TextField source="name" />
        </ReferenceField> 
        :  null}
        <ReferenceField label="Kategória" source="category_id" reference="categories_list" linkType={false} >
            <TextField source="name" />
        </ReferenceField>
        <TextField label="Termék Neve" source="title" />
        <EditButton />
        </Datagrid>
    </List>
);