import { ProductList } from './ProductList';
import { ProductCreate } from './ProductCreate';
import { ProductEdit } from './ProductEdit';
import { ProductShow } from './ProductShow';
import ProductIcon from '@material-ui/icons/Book';

export default {
    list: ProductList,
    edit: ProductEdit,
    show: ProductShow,
    create: ProductCreate,
    icon: ProductIcon,
};
