import React, { Children, cloneElement, Component, isValidElement } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import get from 'lodash/get';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/RemoveCircleOutline';
import AddIcon from '@material-ui/icons/AddCircleOutline';
//import { FormInput } from 'react-admin';

import FormInput from './FormInput';
import { styles } from '../constants';

export class SimpleFormIterator2 extends Component {
    constructor(props) {
        super(props);
        // we need a unique id for each field for a proper enter/exit animation
        // but redux-form doesn't provide one (cf https://github.com/erikras/redux-form/issues/2735)
        // so we keep an internal map between the field position and an autoincrement id
        this.nextId = props.fields.length
            ? props.fields.length
            : props.defaultValue
            ? props.defaultValue.length
            : 0;

        // We check whether we have a defaultValue (which must be an array) before checking
        // the fields prop which will always be empty for a new record.
        // Without it, our ids wouldn't match the default value and we would get key warnings
        // on the CssTransition element inside our render method
        this.ids = this.nextId > 0 ? Array.from(Array(this.nextId).keys()) : [];
    }

    removeField = index => () => {
        const { fields } = this.props;
        this.ids.splice(index, 1);
        fields.remove(index);
    };

    // Returns a boolean to indicate whether to disable the remove button for certain fields.
    // If disableRemove is a function, then call the function with the current record to
    // determing if the button should be disabled. Otherwise, use a boolean property that
    // enables or disables the button for all of the fields.
    disableRemoveField = (record, disableRemove) => {
        if (typeof disableRemove === "boolean") {
            return disableRemove;
        }
        return disableRemove && disableRemove(record);
    }

    addField = () => {
        const { fields } = this.props;
        this.ids.push(this.nextId++);
        fields.push({});
    };

    render() {
        const {
            basePath,
            children,
            fields,
            meta: { error, submitFailed },
            record,
            resource,
            source,
            disableAdd,
            disableRemove,
        } = this.props;
        const records = get(record, source);
        return fields ? (
            <ul>
                {submitFailed && error && (
                    <FormHelperText error>{error}</FormHelperText>
                )}
                    {fields.map((member, index) => (
                            <li style={{ display: 'inline' }}>
                                <div style={styles.divTable}>
                                <div style={styles.divRow}>
                                <section>
                                    {Children.map(children, (input, index2) => isValidElement(input) ? (
                                        <FormInput
                                            basePath={
                                                input.props.basePath || basePath
                                            }
                                            input={cloneElement(input, {
                                                source: input.props.source
                                                    ? `${member}.${
                                                          input.props.source
                                                      }`
                                                    : member,
                                                index: input.props.source
                                                    ? undefined
                                                    : index2,
                                                label:
                                                    input.props.label ||
                                                    input.props.source,
                                            })}
                                            record={
                                                (records && records[index]) ||
                                                {}
                                            }
                                            resource={resource}
                                        />
                                    ) : null)}
                                {!(this.disableRemoveField((records && records[index]) || {}, disableRemove)) && (
                                <div style={styles.divCell}>
                                            <Button
                                            size="small"
                                            onClick={this.removeField(index)}
                                        >
                                            <CloseIcon />
                                            Remove
                                        </Button>
                                </div>
                                )}
                                </section>
                                </div>
                                </div>
                            </li>
                    ))}
                {!disableAdd && (
                    <li style={{ display: 'inline' }}>
                        <span>
                            <Button
                                size="small"
                                onClick={this.addField}
                            >
                                <AddIcon />
                                Add
                            </Button>
                        </span>
                    </li>
                )}
            </ul>
        ) : null;
    }
}

SimpleFormIterator2.defaultProps = {
    disableAdd: false,
    disableRemove: false,
};

SimpleFormIterator2.propTypes = {
    defaultValue: PropTypes.any,
    basePath: PropTypes.string,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    fields: PropTypes.object,
    meta: PropTypes.object,
    record: PropTypes.object,
    source: PropTypes.string,
    resource: PropTypes.string,
    disableAdd: PropTypes.bool,
    disableRemove: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

export default compose(
    withStyles(styles)
)(SimpleFormIterator2);
