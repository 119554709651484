import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextInput } from 'react-admin';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const PasswordInput = ({ defaultVisible, ...otherProps }) => {
    const [visible, setVisible] = useState(defaultVisible);

    return (
        <TextInput
        {...otherProps}
        type={visible ? 'text' : 'password'}
        InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                <IconButton onClick={() => setVisible(!visible)}>
                {visible ? <Visibility /> : <VisibilityOff />}
                </IconButton>
                </InputAdornment>
            ),
        }}
        />
    );
};

PasswordInput.propTypes = {
    defaultVisible: PropTypes.bool,
};

PasswordInput.defaultProps = {
    defaultVisible: false,
};

export default PasswordInput;
