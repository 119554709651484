import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';
import Typography from '@material-ui/core/Typography';

const MyTextField = ({ className, source, data, record = {}, ...rest }) => {
    return (
        <Typography
            component="span"
            body1="body1"
            className={className}
        >
            {data}
        </Typography>
    );
};

MyTextField.propTypes = {
    addLabel: PropTypes.bool,
    basePath: PropTypes.string,
    className: PropTypes.string,
    cellClassName: PropTypes.string,
    headerClassName: PropTypes.string,
    label: PropTypes.string,
    record: PropTypes.object,
    sortBy: PropTypes.string,
    source: PropTypes.string.isRequired,
    data: PropTypes.string,
};

// wat? TypeScript looses the displayName if we don't set it explicitly
MyTextField.displayName = 'MyTextField';
const PureMyTextField = pure(MyTextField);

PureMyTextField.defaultProps = {
    addLabel: true,
};

export default PureMyTextField;
