import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import LabelIcon from '@material-ui/icons/Label';
import { withRouter } from 'react-router-dom';
import {
        DashboardMenuItem,
        MenuItemLink,
        WithPermissions,
} from 'react-admin';

// import resources
import finex from '../leads/finex/';
import mkb from '../leads/mkb/';
import mkb_vallalkozoi from '../leads/mkb_vallalkozoi/';
import cityleasing from '../leads/cityleasing/';
import kh from '../leads/kh/';
import erste from '../leads/erste/';
import minikolcson from '../leads/minikolcson/';
import takarekbank from '../leads/takarekbank/';
import unicredit from '../leads/unicredit/';
import hitelnet from '../leads/hitelnet/';
import sberbank from '../leads/sberbank/';
import crmlist from '../leads/list/';
import users from '../users/';
import promo from '../promo/';
import promo_calc from '../promo_calc/';
import ads from '../ads/';
import oktatas from '../filemanager/oktatas/';
import nyomtatvanyok from '../filemanager/nyomtatvanyok/';
import news from '../filemanager/news/';
import product from '../product/';
import SubMenu from './SubMenu';
import auditlog from '../auditlog';
import banner from '../banner';
import bannerclicks from '../bannerclicks';
import categories from '../categories';
import catmap from '../catmap';
import tags from '../tags';
import clicks from '../clicks';

class Menu extends Component {
        state = {
                    menuLeads: false,
                    menuDocs: false,
                    menuNews: false,
                };

        static propTypes = {
                    onMenuClick: PropTypes.func,
                };

        handleToggle = menu => {
                    this.setState(state => ({ [menu]: !state[menu] }));
                };

        render() {
                    const { onMenuClick, open } = this.props;
                    return (
                                    <div>
                                        {' '}
                                        <DashboardMenuItem onClick={onMenuClick} />
                                        <WithPermissions render={({ permissions }) => (
                                            permissions === 'admin' || permissions === 'hirdeto' ?
                                        <SubMenu
                                            handleToggle={() => this.handleToggle('menuAds')}
                                            isOpen={this.state.menuAds}
                                            sidebarIsOpen={open}
                                            name="Hirdetések"
                                            icon={<LabelIcon />}
                                        >
                                            <MenuItemLink
                                                to={`/ads`}
                                                primaryText="Élő hirdetések"
                                                leftIcon={<ads.icon />}
                                                onClick={onMenuClick}
                                            />
                                            <MenuItemLink
                                                to={`/misc_products`}
                                                primaryText="További Terméknevek"
                                                leftIcon={<product.icon />}
                                                onClick={onMenuClick}
                                            />
                                            <MenuItemLink
                                                to={`/tags`}
                                                primaryText="Címkék"
                                                leftIcon={<tags.icon />}
                                                onClick={onMenuClick}
                                            />
                                            <MenuItemLink
                                                to={`/clicks_report`}
                                                primaryText="CT Statisztika"
                                                leftIcon={<clicks.icon />}
                                                onClick={onMenuClick}
                                            />
                                        </SubMenu>
                                        : null
                                        )}
                                        />
                                        <WithPermissions render={({ permissions }) => (
                                            permissions === 'admin' ?
                                        <MenuItemLink
                                            to={`/crm_list`}
                                            primaryText="CRM Statisztika"
                                            leftIcon={<crmlist.icon />}
                                            onClick={onMenuClick}
                                        /> : null
                                        )}
                                        />
                                        <WithPermissions render={({ permissions }) => (
                                            permissions === 'admin' ?
                                        <MenuItemLink
                                            to={`/ads-featured`}
                                            primaryText="Promóciók"
                                            leftIcon={<promo.icon />}
                                            onClick={onMenuClick}
                                        /> : null
                                        )}
                                        />
                                        <WithPermissions render={({ permissions }) => (
                                            permissions === 'admin' ?
                                        <MenuItemLink
                                            to={`/user2category`}
                                            primaryText="Promóciók Kalkulatorral"
                                            leftIcon={<promo_calc.icon />}
                                            onClick={onMenuClick}
                                        /> : null
                                        )}
                                        />
                                        <WithPermissions render={({ permissions }) => (
                                            permissions === 'admin' ?
                                        <MenuItemLink
                                            to={`/banner`}
                                            primaryText="Bannerek"
                                            leftIcon={<banner.icon />}
                                            onClick={onMenuClick}
                                        /> : null
                                        )}
                                        />
                                        <WithPermissions render={({ permissions }) => (
                                            permissions === 'admin' ?
                                        <MenuItemLink
                                            to={`/banner-clicks`}
                                            primaryText="Banner Kattintások"
                                            leftIcon={<bannerclicks.icon />}
                                            onClick={onMenuClick}
                                        /> : null
                                        )}
                                        />



                                        <WithPermissions render={({ permissions }) => (
                                            permissions === 'admin' ?
                                            <MenuItemLink
                                                to={`/categories`}
                                                primaryText="Kategória Lista"
                                                leftIcon={<categories.icon />}
                                                onClick={onMenuClick}
                                            />
                                        : null
                                        )}
                                        />
                                        <WithPermissions render={({ permissions }) => (
                                            permissions === 'admin' ?
                                            <MenuItemLink
                                                to={`/category_map`}
                                                primaryText="Kalkulátor Paraméterek"
                                                leftIcon={<catmap.icon />}
                                                onClick={onMenuClick}
                                            />
                                        : null
                                        )}
                                        />
                                        <WithPermissions render={({ permissions }) => (
                                            permissions === 'admin' ?
                                        <MenuItemLink
                                            to={`/users`}
                                            primaryText="Felhasználók"
                                            leftIcon={<users.icon />}
                                            onClick={onMenuClick}
                                        /> : null
                                        )}
                                        />
                                        <WithPermissions render={({ permissions }) => (
                                            permissions === 'admin' ?
                                        <SubMenu
                                            handleToggle={() => this.handleToggle('menuLeads')}
                                            isOpen={this.state.menuLeads}
                                            sidebarIsOpen={open}
                                            name="Lakossági Leadek"
                                            icon={<LabelIcon />}
                                        >
                                            <MenuItemLink
                                                to={`/leads`}
                                                primaryText="Belső CRM"
                                                leftIcon={<finex.icon />}
                                                onClick={onMenuClick}
                                            />
                                            <MenuItemLink
                                                to={`/crm_erste`}
                                                primaryText="ERSTE"
                                                leftIcon={<erste.icon />}
                                                onClick={onMenuClick}
                                            />
                                            <MenuItemLink
                                                to={`/crm_kh`}
                                                primaryText="K&H"
                                                leftIcon={<kh.icon />}
                                                onClick={onMenuClick}
                                            />
                                            <MenuItemLink
                                                to={`/crm_minikolcson`}
                                                primaryText="Minikölcsön"
                                                leftIcon={<minikolcson.icon />}
                                                onClick={onMenuClick}
                                            />
                                            <MenuItemLink
                                                to={`/crm_mbh`}
                                                primaryText="MKB"
                                                leftIcon={<mkb.icon />}
                                                onClick={onMenuClick}
                                            />
                                            <MenuItemLink
                                                to={`/crm_sberbank`}
                                                primaryText="Sberbank"
                                                leftIcon={<sberbank.icon/>}
                                                onClick={onMenuClick}
                                            />
                                            <MenuItemLink
                                                to={`/crm_takarekbank`}
                                                primaryText="Takarékbank"
                                                leftIcon={<takarekbank.icon />}
                                                onClick={onMenuClick}
                                            />
                                            <MenuItemLink
                                                to={`/crm_unicredit`}
                                                primaryText="Unicredit"
                                                leftIcon={<unicredit.icon />}
                                                onClick={onMenuClick}
                                            />
                                            <MenuItemLink
                                                to={`/crm_hitelnet`}
                                                primaryText="Hitelnet"
                                                leftIcon={<hitelnet.icon />}
                                                onClick={onMenuClick}
                                            />
                                        </SubMenu>
                                        : null
                                        )}
                                        />
                                        <WithPermissions render={({ permissions }) => (
                                            permissions === 'admin' ?
                                        <SubMenu
                                            handleToggle={() => this.handleToggle('menuCompanyLeads')}
                                            isOpen={this.state.menuCompanyLeads}
                                            sidebarIsOpen={open}
                                            name="Vállalkozói Leadek"
                                            icon={<LabelIcon />}
                                        >
                                            <MenuItemLink
                                                to={`/crm_mbh_vallalkozoi`}
                                                primaryText="MKB"
                                                leftIcon={<mkb_vallalkozoi.icon />}
                                                onClick={onMenuClick}
                                            />
                                            <MenuItemLink
                                                to={`/crm_cityleasing`}
                                                primaryText="City Leasing"
                                                leftIcon={<cityleasing.icon />}
                                                onClick={onMenuClick}
                                            />
                                        </SubMenu>
                                        : null
                                        )}
                                        />
                                        <WithPermissions render={({ permissions }) => (
                                            permissions === 'admin' || permissions === 'kozvetito' ?
                                        <SubMenu
                                            handleToggle={() => this.handleToggle('menuDocs')}
                                            isOpen={this.state.menuDocs}
                                            sidebarIsOpen={open}
                                            name="Dokumentumok"
                                            icon={<LabelIcon />}
                                        >
                                             <MenuItemLink
                                                to={`/filemanager_oktatas`}
                                                primaryText="Oktatás"
                                                leftIcon={<oktatas.icon />}
                                                onClick={onMenuClick}
                                            />
                                            <MenuItemLink
                                                to={`/filemanager_nyomtatvanyok`}
                                                primaryText="Banki nyomtatványok"
                                                leftIcon={<nyomtatvanyok.icon />}
                                                onClick={onMenuClick}
                                            />
                                        </SubMenu>
                                        : null
                                        )}
                                        />
                                        <WithPermissions render={({ permissions }) => (
                                            permissions === 'admin' || permissions === 'ujsagiro' ?
                                        <SubMenu
                                            handleToggle={() => this.handleToggle('menuNews')}
                                            isOpen={this.state.menuNews}
                                            sidebarIsOpen={open}
                                            name="Cikkek"
                                            icon={<LabelIcon />}
                                        >
                                            <MenuItemLink
                                                to={`/filemanager_news`}
                                                primaryText="Újságírói felület"
                                                leftIcon={<news.icon />}
                                                onClick={onMenuClick}
                                            />
                                        </SubMenu>
                                        : null
                                        )}
                                        />
                                        <WithPermissions render={({ permissions }) => (
                                            permissions === 'admin' ?
                                        <MenuItemLink
                                            to={`/auditlog`}
                                            primaryText="Audit Log"
                                            leftIcon={<auditlog.icon />}
                                            onClick={onMenuClick}
                                        /> : null
                                        )}
                                        />
                                    </div>
                                );
                }
}

const mapStateToProps = state => ({
        open: state.admin.ui.sidebarOpen,
});

const enhance = compose(
        withRouter,
        connect(
                    mapStateToProps,
                    {}
                ),
);

export default enhance(Menu);
