import React from 'react';
import { Mutation } from 'react-admin';
import Button from '@material-ui/core/Button';
import Autorenew from '@material-ui/icons/Autorenew';

const ResendButton = ({ record, resource, target, text }) => {
    const options = {
        undoable: false,
        onSuccess: {
            notification: { body: 'Sikeresen újraküldve!', level: 'info' },
            redirectTo: `/${resource}`,
        },
        onFailure: {
            notification: { body: 'HIBA: Újraküldés nem sikerult!', level: 'warning' }
        }
    };

    const payload = { id: record.id, data: { resend: true, target } };

    return (
        <Mutation
        type="UPDATE"
        resource={resource}
        payload={payload}
        options={options}
        >
        {(resend) => (
            <Button variant="text" color="primary" onClick={resend}>
                <Autorenew />{text}
            </Button>
        )}
        </Mutation>
    );
}

export default ResendButton;
