import React from 'react';
import {
    List,
    Show,
    Datagrid,
    TextField,
    Filter,
    ReferenceField,
    ReferenceInput,
    Pagination,
    SimpleShowLayout,
    TextInput,
    SelectInput,
} from 'react-admin';

const AuditFilter = ( { permissions, ...props }) => (
    <Filter {...props}>
        <TextInput label="Termék ID keresése" source="target" alwaysOn />
        <ReferenceInput source="user_id" reference="users" label="Felhasználó" perPage={100} alwaysOn ><SelectInput source="name" /></ReferenceInput>
        <SelectInput label="Action" source="action" choices={[
            { id: 'create', name: 'create' },
            { id: 'update', name: 'update' },
            { id: 'delete', name: 'delete' },
            { id: 'resync', name: 'resync' },
            { id: 'commit_rev', name: 'commit_rev' },
            { id: 'drop_rev', name: 'drop_rev' },
        ]} alwaysOn />
        <SelectInput label="Type" source="type" choices={[
            { id: 'ads', name: 'ads' },
            { id: 'promo', name: 'promo' },
            { id: 'user2category', name: 'user2category'},
            { id: 'user', name: 'user' },
            { id: 'crm', name: 'crm' },
        ]} alwaysOn />
    </Filter>
);

const AuditPagination = props => <Pagination rowsPerPageOptions={[ 10, 25, 50, 100]} {...props} />

export const ExpandAuditShow = props => (
    <Show {...props} title="" >
        <SimpleShowLayout>
            <TextField label="Old value" source="old_value" className="fix-wrap" />
            <TextField label="New value" source="new_value" className="fix-wrap"/>
        </SimpleShowLayout>
    </Show>
);

/*
const AuditPanel = ({ id, record, resource }) => (
    <>
    <pre>Old value</pre>
    <div dangerouslySetInnerHTML={{ __html: JSON.stringify(record.old_value) }} />
    <pre>New value</pre>
    <div dangerouslySetInnerHTML={{ __html: JSON.stringify(record.new_value) }} />
    </>
);
*/

export const AuditList = props => (
    <List {...props} title="Audit Log" pagination={<AuditPagination />} filterDefaultValues={{ type: "ads" }} filters={<AuditFilter />} bulkActions={false} >
        <Datagrid expand={<ExpandAuditShow />}>
            <TextField source="created" showTime/>
            <TextField label="Termék ID" source="target" />
            <ReferenceField label="Felhasználó" source="user_id" reference="users" linkType={false}>
                <TextField source="name" />
            </ReferenceField>
            <TextField source="type" label="Type" />
            <TextField source="action" label="Action" />
        </Datagrid>
    </List>
);
