import {
    FMOktatasList,
    FMOktatasCreate,
    FMOktatasEdit,
} from './oktatas';
import FMOktatasIcon from '@material-ui/icons/Label';

export default {
    create: FMOktatasCreate,
    edit: FMOktatasEdit,
    list: FMOktatasList,
    icon: FMOktatasIcon,
};
