import {
    FMNewsList,
    FMNewsCreate,
    FMNewsEdit,
} from './news';
import FMNewsIcon from '@material-ui/icons/Label';

export default {
    create: FMNewsCreate,
    edit: FMNewsEdit,
    list: FMNewsList,
    icon: FMNewsIcon,
};
