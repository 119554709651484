import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Autorenew from '@material-ui/icons/Autorenew';
import { Button, startUndoable, crudUpdateMany } from 'react-admin';

class BulkResendButton extends Component {
    handleClick = () => {
        const { basePath, startUndoable, resource, selectedIds } = this.props;
        startUndoable(
            crudUpdateMany(
                resource,
                selectedIds,
                { resend: true },
                basePath
            )
        );
    };

    render() {
        return (
            <Button
            label="Újraküldés"
            onClick={this.handleClick}
            >
            <Autorenew />
            </Button>
        );
    }
}

BulkResendButton.propTypes = {
    resource: PropTypes.string.isRequired,
    selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    startUndoable: PropTypes.func.isRequired,
};

export default connect(
    undefined,
    { startUndoable }
)(BulkResendButton);
