import React from 'react';

import { SelectInput, ReferenceInput, TextField } from 'react-admin';
import { ZeroOneBooleanInput } from './bool';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

export const MyUser = ( props ) => {
    var command;
    if (props.admin) {
        command = <ReferenceInput label="Hirdető" source="user" reference="users" perPage={100} resource={props.resource} ><SelectInput optionText="name" /></ReferenceInput>;
    } else {
        command = null;
    }
    return ( command );
};

export const MyStatus = ( props ) => {
    var command;
    if (props.admin) {
        command = <SelectInput label="Státusz" source="status" choices={[
            {status: 0, name: "elfogadásra vár" },
            {status: 1, name: "aktív" },
            {status: 2, name: "inaktív" },
            {status: 3, name: "archív" },
            {status: 4, name: "időzített" },
        ]} optionText="name" optionValue="status" />;
    } else if (props.banner) {
        command = <SelectInput label="" source="status" choices={[
            {status: 0, name: "felfüggesztett" },
            {status: 1, name: "aktív" },
            {status: 2, name: "lezárt" },
            {status: 3, name: "törölt (véglegesen törölve)" },
        ]} optionText="name" optionValue="status" />;
    } else {
        command = <SelectInput label="Státusz" source="status" choices={[
            {status: 0, name: "elfogadásra vár" },
        ]} optionText="name" optionValue="status" />;

    }
    return ( command );
};

export const MyClickType = ( props ) => {
    var command;
    if (props.admin) {
        command = <ZeroOneBooleanInput label="Lead hirdetés?" source="click_type" />;
    } else {
        command = null;
    }
    return ( command );

};

export const TooltipTextField = ( props, ...rest) => {
    return (
        <>
            <Tooltip
                title={
                <React.Fragment>
                    <Typography color="inherit">{props.title}</Typography>
                </React.Fragment>
                }
                placement="top"
            >
                <TextField record={props.record} source={props.source} />
            </Tooltip>
        </>
    );
}

