import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_GET_PERMISSIONS, AUTH_CHECK, AUTH_ERROR } from 'react-admin';
import { API_URL } from '../config';

export default (type, params) => {
    if (type === AUTH_LOGIN) {
        const { username, password } = params;
        sessionStorage.setItem('username', username);
        const myBody = {
            username: username,
            password: password,
        };
        const request = new Request(`${API_URL}/login`, {
            method: 'POST',
            body: JSON.stringify(myBody),
            headers: new Headers({ 'Content-Type': 'application/json',
                'Accept': 'application/json'}),
        })
        return fetch(request)
           .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(json => { sessionStorage.setItem('userdata', JSON.stringify(json)); console.log(JSON.stringify(json)); return json; })
            .then(({ token }) => {
                sessionStorage.setItem('token', token);
            });
    }
    if (type === AUTH_GET_PERMISSIONS) {
        let newrole = sessionStorage.getItem('userdata');
        newrole = JSON.parse(newrole);
        if ( parseInt(newrole.type) === 1 ) {
            sessionStorage.setItem('role', 'admin');
        } else if ( parseInt(newrole.type) === 2 ) {
            sessionStorage.setItem('role', 'hirdeto');
        } else if ( parseInt(newrole.type) === 3 ) {
            sessionStorage.setItem('role', 'kozvetito');
        } else if ( parseInt(newrole.type) === 4 ) {
            sessionStorage.setItem('role', 'media');
        } else if ( parseInt(newrole.type) === 5 ) {
            sessionStorage.setItem('role', 'ujsagiro');
        } else {
            sessionStorage.setItem('role', 'user');
        }
        const role = sessionStorage.getItem('role');
        return Promise.resolve(role);
    }
    if (type === AUTH_CHECK) {
        return sessionStorage.getItem('token')
            ? Promise.resolve()
            : Promise.reject();
    }
    if (type === AUTH_ERROR) {
    	const status = params.status;
	if (status === 401 || status === 403) {
	    sessionStorage.removeItem('token');
	    return Promise.reject();
	}
	return Promise.resolve();
    }
    if (type === AUTH_LOGOUT) {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('role');
        sessionStorage.removeItem('userdata');
        sessionStorage.removeItem('username');
        return Promise.resolve();
    }
    return Promise.reject();

}
